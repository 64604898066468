/* Store state relating to boarding pass. */
import { resettable } from './extensions/resettable';

/** Boarding Pass printing setter */
// eslint-disable-next-line import/prefer-default-export
export const printBoardingPass = resettable(false);

/** User selected yes to seat map */
// eslint-disable-next-line import/prefer-default-export
export const selectedYesToSeatMapSelection = resettable(null);

