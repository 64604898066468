<script>
  import { _ } from 'svelte-i18n';
  import Content from '../components/Content.svelte';
  import { onMount } from 'svelte';
  import { push } from 'svelte-spa-router';
  import Header from '../components/Header/index.svelte';
  import logger from '../../js/logger';
  import Loading from '../components/Loading.svelte';
  import Footer from '../components/Footer/index.svelte';

  onMount(() => {    
    logger.info(`showing please wait page for 2 seconds.`)
    
    setTimeout(() => {
      logger.info(`Now redirecting back to seat map.`)
      push('/seat-map');
    }, 2000);
  });
</script>

<Header />

<Content>
  <span slot="heading">{$_('seatMap.heading')}</span>
</Content>

<Loading heading={$_('app.pleaseWait')} />

<Footer />
