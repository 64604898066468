<script>
  import { _ } from 'svelte-i18n';
  import { onMount } from 'svelte';
  import { push } from 'svelte-spa-router';
  import { get } from 'svelte/store';

  import { ApplicationStep, appReport } from '../../js/appReport';
  import { booking, wizardPosition, selectedYesToSeatMapSelection } from '../../js/stores';
  import { VoiceIntent } from '../../js/services/voicerec/voicerec';
  import { WizardPosition } from '../../js/const';

  import BoardingPass from '../components/modal/BoardingPass.svelte';
  import BookingSummarySingle from '../components/BookingSummarySingle.svelte';
  import ChevronRight from '../components/Icons/ChevronRight.svelte';
  import Content from '../components/Content.svelte';
  import Footer from '../components/Footer/index.svelte';
  import FooterSecondary from '../components/FooterSecondary.svelte';
  import Header from '../components/Header/index.svelte';
  import RadioButton from '../components/RadioButton.svelte';
  import RadioButtonGroup from '../components/RadioButtonGroup.svelte';

  wizardPosition.set(WizardPosition.PASSENGERS);

  let showPrintBoardingPassModal = null;
  //let localSeatMapSelection = null;

  const options = [{text: $_('app.yes'), value: true}, {text: $_('app.no'), value: false}];

  /** Advance to the next screen as per the applicationFlow. */
  function advanceToNextScreen() {
    appReport.updateStepSuccess(ApplicationStep.FLIGHT_SUMMARY);

    if (
      booking.hasUncheckedInPassengers() ||
      booking.hasPassengersRequiringADCCheck()
    ) {
      push('/passport-verification/');
    } else {
      // Note: In single pax flow, the check for checked-in passengers comes here
      // But in multi pax in ScanPassport the option will be presented but the flow determined in passport-verification.
      if(get(selectedYesToSeatMapSelection))
      {
        push('/seat-map');
      }
      else
      {
        push('/number-of-bags')
      }
    }
  }
 
  /** The handler for the Boarding Pass button. */
  function boardingPassHandler() {
    if (booking.hasCheckedInPassengers()) {
      showPrintBoardingPassModal = true;
    } else {
      advanceToNextScreen();
    }
  }

  function changeSeatHandler() {
    selectedYesToSeatMapSelection.set(true);

    boardingPassHandler();
  }

  onMount(() => {
    appReport.updateStepStart(ApplicationStep.FLIGHT_SUMMARY);
  });
</script>

<BoardingPass
  {advanceToNextScreen}
  bind:showModal={showPrintBoardingPassModal}
/>

<Header />

<Content>
  <span slot="heading">
    {$_('welcome.heading', {
      values: {
        title: String(
          get(booking)?.passengers[0]?.title
            ? booking.getTranslatedTitle(get(booking)?.passengers[0]?.title)
            : '',
        ),
        firstName: String(get(booking)?.passengers[0]?.firstName),
        lastName: String(get(booking)?.passengers[0]?.lastName),
      },
    })}
  </span>

  <div slot="main">
    <BookingSummarySingle />

    <FooterSecondary
      buttonRightHandler={boardingPassHandler}
      buttonRightIcon={ChevronRight}
      buttonLeftVisible={!booking.isAnyPassengerStaffandStandBy() && (!booking.hasUncheckedInPassengers() && !booking.hasPassengersRequiringADCCheck())}
      buttonLeftHandler={changeSeatHandler}
      buttonLeftText={(!booking.isAnyPassengerStaffandStandBy() && (!booking.hasUncheckedInPassengers() && !booking.hasPassengersRequiringADCCheck())) ? $_('seatMap.changeSeat') : null}
      hasTripExtrasBanner
    />
  </div>
</Content>

<Footer />
