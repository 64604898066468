import { _, locale } from 'svelte-i18n';
import { get } from 'svelte/store';

import { currencyFormat } from '../utils';
import { PaymentController } from '../controllers/payment';
import { PaymentItem } from '../paymentItem';
import { SEAT_SUBCODE } from '../const';

const translate = get(_);
const seatQuantity = '1';
const serviceGroup = 'SA';
const description = 'Seat upgrade';

/**
 * Payment controller for seat payment.
 */
export class SeatPayment extends PaymentController {
  /**
   * Instantiate the seatPayment.
   *
   * @param {object} seat - Seat object.
   * @param {Function} handleAccepted - Callback for a successful payment.
   * @param {Function} handleDeclined - Callback for a declined payment.
   */
  constructor(totalAmount, currency, handleAccepted, handleDeclined) {
    console.log(`inside constructor of seatPayment. values are: totalAmount: ${totalAmount}, currency: ${currency}`);
    super(totalAmount, currency, handleAccepted, handleDeclined);
  }

  /**
   * Returns the heading of the seat payment.
   *
   * @returns {string}
   */
  getPaymentItemHeading() {
    return translate('payment.purchaseSeatUpgrades');
  }

  /**
   * Returns the purchases of the seat payment.
   *
   * @returns {string}
   */
  getPaymentItemPurchases() {
    return translate('payment.seatPaymentPurchases', {
      values: {
        seat: this.seat,
        amount: currencyFormat(
          get(locale),
          this.currency,
          this.extraAmount,
        ),
      },
    });
  }

  /**
   * Accept multiple payment items for seat
   * @param {number} totalPrice
   * @param {number} basePrice
   * @param {string} requestedSeat
   * @param {string} assignedSeat
   */
  addItem(totalPrice, basePrice, requestedSeat, assignedSeat) {
    this.items.push(
      new PaymentItem(
        SEAT_SUBCODE,
        totalPrice,
        basePrice,
        description,
        seatQuantity,
        serviceGroup,
        assignedSeat,
        requestedSeat,
      ),
    );
  }
}
