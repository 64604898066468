/* eslint-disable max-classes-per-file */
import { get } from 'svelte/store';
import { booking } from './booking';
import { FARE_CODE_MAP } from '../const';
import logger from '../logger';
import { resettable } from './extensions/resettable';
import switchboardClient from '../services/switchboard';
import { mapFareCode } from '../utils';

// DummyData for use with retrieveDummySeatMap.
import a320J from '../data/mocks/seatMap/320-business.json'; // equipment 320
import a320Y from '../data/mocks/seatMap/320-economy.json';
import a321Y from '../data/mocks/seatMap/321-economy.json';
import a380A from '../data/mocks/seatMap/388-first.json';
import a380J from '../data/mocks/seatMap/388-business.json';
import a380Y from '../data/mocks/seatMap/388-economy.json';
import b777A from '../data/mocks/seatMap/77w-first.json';
import b781Y from '../data/mocks/seatMap/781-economy.json';
import b787J from '../data/mocks/seatMap/789-business.json';
import b787Y from '../data/mocks/seatMap/789-economy.json';

const seatMaps = resettable([]); 
const currentSeatMap = resettable({});

// NOTE: These values must match exactly with whatever switchboard is returning.
const AISLE_CHARACTERISTIC = 'Aisle';
const AISLE_LOCATION_DETAIL = 'AisleSeat';
const BAR_FACILITY_DETAIL = 'Bar';
const STAIRS_FACILITY_DETAIL = 'StairstoUpperdeck';
const BASSINET_FACILITY_DETAIL = 'SeatWithBassinetFacility';
const ECONOMY_SPACE_FACILITY_DETAIL = 'LegSpaceSeat';
const BULKHEAD_CHARACTERISTIC = 'Bulkhead';
const BULKHEAD_LOCATION_DETAIL = 'BulkheadSeat';
const EXIT_ROW_CHARACTERISTIC = 'EXIT';
const EXIT_LOCATION_DETAIL = 'ExitRowSeat';
const GALLEY_CHARACTERISTIC = 'Galley';
const LAVATORY_CHARACTERISTIC = 'Lavatory';
const NO_SEAT_LOCATION_DETAIL = 'NoSeatAtThisLocation';
const NO_SEAT_LAVATORY_LIMITATION_DETAIL = 'NoSeatLavatory';
const OVERWING_LOCATION_DETAIL = 'OverWingSeatS';
const REAR_FACING_SEAT_LIMITATION_DETAIL = 'RearFacingSeat';
const WINDOW_LOCATION_DETAIL = 'Window';

const SEAT_BLOCK_CODES = Object.freeze({
  INFANT: 'I',
  JUMP_SEAT: 'JS',
  CREW_REST: '@',
  COURTESY: 'O',
  PRE_RESERVED_SEAT: 'R',
  PREMIUM_PRS_SEAT: 'V',
  AIRPORT_ASSIGN_ONLY: 'A',
  BROKEN_SEAT: '¥',
  BUFFER_ZONE: 'Z',
  THRU_PASSENGER: 'T',
  PREMIUM_BLOCK: 'F',
});

const EQUIPMENT_MAP = Object.freeze({
  '32A': 'Airbus A320',
  320: 'Airbus A320',
  321: 'Airbus A321',
  332: 'Airbus A330',
  333: 'Airbus A330',
  388: 'Airbus A380-800',
  '77W': 'Boeing 777-300ER',
  781: 'Boeing 787-10',
  789: 'Boeing 787-9',
});

/**
 * Groups elements of an array, converting it into an array of arrays.
 *
 * @param {any[]} source - The array to be grouped
 * @param {Function} endGroupTest - A function that will determine when an item
                                    within source is the end of a group.
 * @param {Function} decorateItem - A function to decorate an item from source
                                    when it is appended to the inner array.
 * @returns {any[][]} The array of groups.
 */
function reduceToGroups(source, endGroupTest, decorateItem) {
  return source.reduce(
    (groups, item, currentIndex) => {
      const currentGroup = () => {
        return groups ? groups[groups.length - 1] : null;
      };
      const currentGroupIsStarted = () => {
        return currentGroup() ? currentGroup().length !== 0 : null;
      };
      const reachedGroupEnd = currentGroupIsStarted() && endGroupTest(item);

      if (currentGroup()) {
        currentGroup().push(decorateItem(item));
      }

      let shouldStartNewGroup = reachedGroupEnd
      if (shouldStartNewGroup) {
        groups.push([]);
      }
      return groups;
    },
    [[]],
  );
}

/**
 * Encapsulates the Switchboard Seat object.
 */
class Seat {
  /**
   * Sets the switchboard seat and row objects.
   *
   * @param {object} row - Switchboard Row object.
   * @param {object} seat - Swtichboard Seat object.
   */
  constructor(row, seat) {
    this.seat = seat;
    this.row = row;
  }

  /**
   * Returns the currency of this seat.
   *
   * @returns {string}
   */
  getCurrencyCode() {
    return this.seat.offer?.price?.currency ? this.seat.offer?.price?.currency : 'AED';
  }
  
  /**
   * Returns the cost of this seat.
   *
   * @returns {string}
   */
  getPrice() {
    return this.seat.offer?.price?.amount ? this.seat.offer?.price?.amount : 0;
  }

  /**
   * Is the seat an aisle seat?
   *
   * @returns {boolean}
   */
  isAisle() {
    return (this.seat.locationDetails || []).includes(AISLE_LOCATION_DETAIL);
  }

  /**
   * Can the seat be taken by a passenger?
   *
   * @param {object} passenger - Switchboard passenger object.
   * @returns {boolean}
   */
  isAvailable(passenger = null) {
    let isAvailable = null;

    isAvailable = !this.seat.occupiedInd;

    /** Restricted seating for passengers with infants. */
    if (passenger && booking.passengerHasInfant(passenger)) {
      isAvailable = isAvailable && !this.seat.noInfantInd;
    }

    return isAvailable;
  }

  isWindowSeatWithoutWindow() {
    return this.seat.isWindowSeatWithoutWindow;
  }

  isCenterSeat() {
    return this.seat.isCenterSeat;
  }

  isAisleSeatLocation() {
    return (this.seat.locationDetails || []).includes(
      AISLE_LOCATION_DETAIL,
    );
  }

  isFrontOfCabin() {
    return this.seat.isFrontOfCabin;
  }

  isRearFacingSeat() {
    return this.seat.isRearFacingSeat;
  }

  /**
   * Does the seat have a bassinet facility?
   *
   * @returns {boolean}
   */
  isBassinet() {
    return (this.seat.facilitiesDetail || []).includes(
      BASSINET_FACILITY_DETAIL,
    );
  }

  /**
 * Is the seat in front of a bar ?
 *
 * @returns {boolean}
 */
  isBar() {
    return (this.seat.facilitiesDetail || []).includes(
      BAR_FACILITY_DETAIL,
    );
  }

  isStairs() {
    return (this.seat.facilitiesDetail || []).includes(
      STAIRS_FACILITY_DETAIL,
    )
  }
  

  /**
   * Is the seat in front of a bulkhead?
   *
   * @returns {boolean}
   */
  isBulkhead() {
    return (this.seat.locationDetails || []).includes(
      BULKHEAD_LOCATION_DETAIL,
    );
  }

  /**
   * Does the seat have a Economy Space facility?
   *
   * @returns {boolean}
   */
  isEconomySpace() {
    return (this.seat.facilitiesDetail || []).includes(
      ECONOMY_SPACE_FACILITY_DETAIL,
    );
  }

  /**
   * Is the seat an empty space?
   *
   * @returns {boolean}
   */
  isEmpty() {
    return (
      (this.seat.locationDetails || []).includes(NO_SEAT_LOCATION_DETAIL) ||
      (this.seat.limitationDetails || []).includes(
        NO_SEAT_LAVATORY_LIMITATION_DETAIL,
      )
    );
  }

  /**
   * Is the seat an emergency exit seat?
   *
   * @returns {boolean}
   */
  isEmergencyExit() {
    return this.seat.exitRowInd;
  }

  /**
   * Is the seat a lavatory seat?
   *
   * @returns {boolean}
   */
  isLavatory() {
    return (this.seat.limitationDetails || []).includes(
      NO_SEAT_LAVATORY_LIMITATION_DETAIL,
    );
  }

  /**
   * Is the seat occupied by another passenger?
   *
   * @returns {boolean}
   */
  isOccupied() {
    return this.seat.occupiedInd;
  }

  /**
   * Does the seat require payment?
   *
   * @returns {boolean}
   */
  isPaid() {
    return (this.seat?.chargeableInd && (Number(this.seat?.offer?.price?.amount) >= 0));
  }

  hasPassengerPaid(passenger) {
    if (booking.hasPassengerPaidForSeat(passenger, this.flightNumber)) {
      return true;
    } else {
      return false;
    }
  }

  isSeatAmountGreatorThanZero() {
    return Number(this.seat?.offer?.price?.amount) > 0;
  }

  isChargeable() {
    return this.seat?.chargeableInd;
  }

  /**
   * Is the seat a rear facing seat?
   *
   * @returns {boolean}
   */
  isRearFacing() {
    return (this.seat.limitationDetails || []).includes(
      REAR_FACING_SEAT_LIMITATION_DETAIL,
    );
  }

  isLeftSideOfAircraft() {
    return this.seat.isLeftSideOfAircraft;
  }

  isSeatWithoutAMovieView() {
    return this.seat.isSeatWithoutAMovieView;
  }

  isPreferentialSeat() {
    return this.seat.isPreferentialSeat;
  }

  isRightSideOfAircraft() {
    return this.seat.isRightSideOfAircraft;
  }

  isWindowAndAisleTogether() {
    return this.seat.isWindowAndAisleTogether;
  }

  /**
   * Is the seat a special needs seat?
   *
   * @TODO Needs implementation
   * @returns {boolean}
   */
  isSpecialNeeds() {
    return false;
  }

  /**
   * Does the seat have a window?
   *
   * @returns {boolean}
   */
  isWindow() {
    return (this.seat.locationDetails || []).includes(WINDOW_LOCATION_DETAIL);
  }

  /**
   * String representation of the seat.
   *
   * @returns {string}
   */
  toString() {
    //return `${this.row.number}${this.seat.column}`;
    return `${this.seat.seatNumber}`;
  }
}

/**
 * Used for accessing the seat map.
 */
export class SeatMapManager {
  /**
   * Sets the flightNumber.
   *
   * @param {string} flightNumber
   */
  constructor(flightNumber) {
    this.setFlightNumber(flightNumber);
  }

  /**
   * Extract the Cabin object from the Switchboard SeatMap.
   *
   * @returns {object} The Cabin object, or an empty object.
   */
  cabin() {
    const cabins = get(currentSeatMap)['cabin'] || [];
    return cabins.length === 1 ? cabins[0] : {};
  }

  /**
   * Return a field of the Switchboard Cabin object, using a fieldname.
   *
   * Instead of `this`, the instance `seatMapManager` is used.
   *
   * @param {string} fieldname The field of the Cabin object to return.
   * @returns {any} The matching value, or null.
   */
  cabinField(fieldname) {
    return this.cabin()[fieldname] || null;
  }

  /**
   * Determine whether a column is adjacent to an aisle.
   *
   * @param {string} columnName - The column name (eg. 'A' or 'B', etc.)
   * @returns {boolean} Whether the column is adjacent to an aisle.
   */
  isAisleColumn(columnName) {
    const characteristics = this.columnCharacteristics(columnName);
    return characteristics.includes(AISLE_CHARACTERISTIC);
  }

  /**
   * Determine whether a seat is an aisle seat.
   *
   * @param {object} seat - A seat object from Switchboard.
   * @returns {boolean} - Whether or not the seat is an aisle seat.
   */
  isAisleSeat(seat) {
    const { column } = seat;
    return this.isAisleColumn(column);
  }

  /**
   * Gets the rows for the cabin
   *
   * @returns {object[]} Array containing rows for the cabin.
   */
  getRows() {
    return this.cabinField('rows') || [];
  }

  /**
   * Whether or not the cabin has economy space seats.
   *
   * @returns {boolean} Whether or not the cabin has economy space seats.
   */
  hasEconomySpaceSeats() {
    return this.getRows().some((row) =>
      (row.seat || []).some((seat) =>
        (seat.facilitiesDetail || []).includes(ECONOMY_SPACE_FACILITY_DETAIL),
      ),
    );
  }

  /**
   * Whether or not the cabin has rear facing seats.
   *
   * @returns {boolean} Whether or not the cabin has rear facing seats.
   */
  hasRearFacingSeats() {
    return this.getRows().some((row) =>
      (row.seat || []).some((seat) =>
        (seat.limitationDetails || []).includes(
          REAR_FACING_SEAT_LIMITATION_DETAIL,
        ),
      ),
    );
  }

  /**
   * Whether or not the aircraft is economy class.
   *
   * @returns {boolean} Whether or not the aircraft is economy class.
   */
  isEconomyClass() {
    const cabinClass = this.cabinField('class');
    return FARE_CODE_MAP['Economy'].includes(cabinClass);
  }

  /**
   * Whether or not a particular row is a row with an exit seat.
   *
   * @param {object} row - A Row object from Switchboard.
   * @returns {boolean} Whether or not the row has an exit seat.
   */
  isExitRow(row) {
    var isExitRow = (row.rowCharacteristics || []).includes(EXIT_ROW_CHARACTERISTIC); 
    return isExitRow;
  }

  /**
   * Whether or not a facility is at the front or rear of a row.
   *
   * @param {object} row - A Row object from Switchboard.
   * @returns {boolean} Whether or not the facility is at the front or the rear.
   */
  isFrontFacility(row) {
    return (row.rowFacility || []).some((rowFacility) => {
      return rowFacility.location === 'Front';
    });
  }

  isRearFacility(row) {
    return (row.rowFacility || []).some((rowFacility) => {
      return rowFacility.location === 'Rear';
    });
  }

  /**
   * Whether an entire row is a facility.
   */
  isFacilityRow(row) {
    if(row.type && row.type === "FACILITY")
    {
      return true
    }
    return false;
  }
  

  /**
   * Whether or not a particular row is an overwing row.
   *
   * @param {object} row - A Row object from Switchboard.
   * @returns {boolean} Whether or not the row is overwing.
   */
  isOverWingRow(row) {
    return (row.seat || []).some((seat) => {
      return (seat.locationDetails || []).includes(OVERWING_LOCATION_DETAIL);
    });
  }

  /**
   * Return array of characteristics for a column (as returned by Switchboard)
   *
   * @param {string} columnName - The column name, eg. 'A'
   * @returns {string[]} - Array of characteristics.
   */
  columnCharacteristics(columnName) {
    const columns = this.cabinField('columns');
    const found =
      (columns || []).find((column) => column.columnName === columnName) || {};
    return found.characteristics || [];
  }

  /**
   * Return seat groups for the row.
   *
   * Seat groups are separated by aisles.
   *
   * @param {object} row - The switchboard Row object.
   * @returns {string[][]} Array of arrays of seat numbers.
   */
  getGroupsForRow(row) {
    var grouping = reduceToGroups(
      row.seat || [],
      (seat) => this.isAisleSeat(seat),
      (seat) => (seat.column ? new Seat(row, seat) : null),
    );
    
    return grouping
  }

  /**
   * Return column groups for the column heading.
   *
   * Column groups are separated by aisles.
   *
   * @returns {string[][]} Array of array of column names.
   */
  getGroupsForColumnHeadings() {
    const columns = this.cabinField('columns') || [];
    const columnGroups = reduceToGroups(
      columns,
      (column) => {
        return this.isAisleColumn(column.columnName);
      },
      (column) => column.columnName,
    );
    return columnGroups;
  }

  /**
   * Get name of a cabin class as a string.
   *
   * @returns {string} Name of cabin class, or empty string.
   */
  getCabinClassName() {
    const cabinClass = this.cabinField('class');
    return mapFareCode(cabinClass);
  }

  /**
   * Get name of the equipment as a string.
   *
   * If the equipment code doesn't match EQUIPMENT_MAP, returns empty string.
   *
   * @returns {string} Name of equipment, or empty string.
   */
  getEquipmentName() {
    const equipment = get(currentSeatMap)['equipment'];
    return EQUIPMENT_MAP[equipment] || '';
  }

  /**
   * Return facilities for the row.
   *
   * @param {object} row - The switchboard Row object.
   * @returns {object[]} Array of facilities.
   */
  getRowFacilities(row) {
    return row.rowFacility || [];    
  }

  /**
   * Sets the seatMap of the currently selected passenger.
   *
   * @returns {object[]} Array of facilities.
   */
  setCurrentSeatMap(passengerId) {
    let allSeatMaps = get(seatMaps)[this.flightNumber];
    if(allSeatMaps.length === 0) 
    {
      throw new Error(`Unable to set seatmap as no seatmaps exist for flight: ${this.flightNumber}`)
    } 
    
    // Return the seat map of the currently selected passenger
    var paxSeatMap = allSeatMaps.find(seatMap => seatMap.passengerIds?.length > 0 && seatMap.passengerIds.includes(passengerId));

    if(!paxSeatMap)
    {
      currentSeatMap.set(allSeatMaps[0]);
    } else {
      currentSeatMap.set(paxSeatMap);
    }
  }
  
  /**
   * Returns the current seatmap
   * @returns 
   */
  getCurrentSeatMap()
  {
    return (currentSeatMap || {})
  }

  /**
   * Return the maximum number of groups from all rows.
   * @returns {number} - The maximum number of groups.
   */
  maxGroups() {
    return this.getRows().reduce((max, row) => {
      let rowGroups = this.getGroupsForRow(row).length;
      return rowGroups > max ? rowGroups : max;
    }, 0);
  }

  /**
   * Return the maximum number of seats from all rows.
   * @returns {number} - The maximum number of seats.
   */
  maxSeats() {
    return this.getRows().reduce((max, row) => {
      let rowSeats = (row.seat || []).length;
      return rowSeats > max ? rowSeats : max;
    }, 0);
  }

  /**
   * Retrieves the seat map of the current booking and stores it.
   *
   * @returns {promise} A promise that resolves when the retrieval is complete.
   */
  retrieveSeatMap() {
    logger.info(`Request seatMap.`);

    return switchboardClient
      .seatMap(booking)
      .then((response) => {
        if (response && response.data && response.data.seatMap 
          && response.data.seatMap.seatMaps) {
          logger.info(`Received seatMap response.`);
        }

        seatMaps.set({
          [this.flightNumber]: response.data.seatMap.seatMaps,
        });
      })
      .catch((error) => {
        logger.warn(`Failed seatMap: ${error.message}`);
        throw error;
      });
  }

  /**
   * Retrieves a dummy seat map and stores it.
   *
   * @returns {Promise} A promise that resolves when the retrieval is complete.
   */
  retrieveDummySeatMap(dummyDataName) {
    const DummyDataMap = {
      '320-business': a320J,
      '320-economy': a320Y,
      '321-economy': a321Y,
      '388-business': a380J,
      '388-economy': a380Y,
      '388-first': a380A,
      '77w-first': b777A,
      '781-economy': b781Y,
      '789-business': b787J,
      '789-economy': b787Y,
    };

    const dummyData =
      dummyDataName in DummyDataMap ? DummyDataMap[dummyDataName] : {};
    if (dummyData) {
      logger.info(dummyData.seatMap);
    }
    seatMap.set({ ...get(seatMap), [this.flightNumber]: dummyData.seatMap });
    return Promise.resolve();
  }

  setFlightNumber(flightNumber = null) {
    if (!flightNumber) {
      flightNumber = get(booking).flightNumber;
    }
    this.flightNumber = flightNumber || get(booking).flightNumber;
    return this;
  }
}

/** Resets the store. */
export function resetSeatMap() {
  seatMap.reset();
}
