<script>
  import { createEventDispatcher } from 'svelte';

  import Available from './seats/Available.svelte';
  import EconomySpace from './seats/EconomySpace.svelte';
  import EconomySpaceSelected from './seats/EconomySpaceSelected.svelte';
  import Lavatory from './seats/Lavatory.svelte';
  import RearSeat from './seats/RearSeat.svelte';
  import RearSeatSelected from './seats/RearSeatSelected.svelte';
  import SeatIcon from './SeatIcon.svelte';
  import Selected from './seats/Selected.svelte';

  export let isOpen = null;
  export let isSeatContentVariation = null;
  export let passenger = null;
  export let passengerSeats = null;
  export let previewSeat = null;
  export let selectedSeat = null;
  export let seat = null;

  const dispatch = createEventDispatcher();

  let description = null;
  let disabled = null;
  let iconRef = null;
  let seatType = null;
  let text = null;

  /**
   * @TODO
   *   SpecialNeeds PAX can not sit on exit row seats.
   *   Medical PAX will have MEDA SSR which is restricted.
   *   Comment in EYSS-1470.
   */

  $: if (iconRef) {
    const isPreviewSeat = String(seat) === previewSeat;
    const isSelectedSeat = String(seat) === selectedSeat;
    const isCoPassengerSeat =
      passengerSeats.includes(String(seat)) && !isSelectedSeat;

    if (isSelectedSeat || isCoPassengerSeat || isPreviewSeat) {
      if (seat.isEconomySpace()) {
        seatType = EconomySpaceSelected;
      } else if (seat.isRearFacing()) {
        seatType = RearSeatSelected;
      } else {
        seatType = Selected;
      }
      text = '';
    } else if (seat.isEmpty()) {
      if (seat.isLavatory()) {
        seatType = Lavatory;
      } else {
        seatType = null;
      }
    } else if (seat.isRearFacing()) {
      description = 'rear';
      seatType = RearSeat;
      text = '';
    } else if (seat.isEmergencyExit()) {
      description = 'exitRow';
      seatType = Available;
      text = 'Exit Row';
    } else if (seat.isEconomySpace()) {
      description = 'economySpace';
      seatType = EconomySpace;
      text = '';
    } else {
      description = '';
      seatType = Available;
      text = '';
    }

    iconRef.style.opacity = '1';

    if (seat.isAvailable(passenger)) {
      disabled = false;
      if (
        isPreviewSeat ||
        seat.isLavatory()
      ) {
        disabled = true;
        iconRef.style.opacity = '0.5';
      } 
    } else {
      disabled = true;
      if (!isSelectedSeat) {
        iconRef.style.opacity = '0.5';
      }
    }

    if (seat.isEmergencyExit() && passenger?.amadeusPassengerType === 'C') {
      disabled = true;
      iconRef.style.opacity = '0.5';
    }

    if (isSelectedSeat && !isOpen) {
      dispatch('scrollToSeat', {
        iconRef,
      });
    }
  }

  /** Handle the seat icon button. */
  function openMenuHandler() {
    dispatch('selectSeat', {
      description,
      iconRef,
      seat,
    });
  }
</script>

<button {disabled} on:click={openMenuHandler} bind:this={iconRef}>
  <SeatIcon {isSeatContentVariation} {seat} {seatType} {text} />
</button>
