/* Client for Switchboard. */
import { get } from 'svelte/store';
import FetchQL from 'fetchql';
import {
  baseStationCode,
  baseStationCurrency,
  switchboardUri,
  switchboardApiKey,
} from '../stores/config';

import {
  dateTimeToDate,
  dateToAirportFormat,
  safeObjectAttribute,
  stripLeadingZeroes,
} from '../utils';
import { transactionId } from '../stores/transactionId';
import { headPassenger, seatPaymentProcessInProgress } from '../stores';


const EMAIL_EMD_ISSUANCE_FAILED = `
  query emailEMDIssuanceFailed($emailDetails: EmailEMDIssuanceFailedInput) {
    emailEMDIssuanceFailed(emailDetails: $emailDetails) {
      isSuccessful
    }
  }
`

const OVERRIDE_EXCESS_BAGGAGE = `
mutation overrideExcessBaggage($overrideExcessBaggageInput: OverrideExcessBaggageInput) {
  overrideExcessBaggage(overrideExcessBaggageInput: $overrideExcessBaggageInput) {
    isGetSuccessful
    errorMessages
    baseStation
    transactionId
  }
}
`;

const UPDATE_BAGGAGE_INFORMATION = `
mutation updateBaggageInformation($updateBaggageInformationInput: UpdateBaggageInformationInput!) {
  updateBaggageInformation(updateBaggageInformationInput: $updateBaggageInformationInput) {
    isUpdateSuccessful    
    errorMessages {
      code
      message
    }
    unpaidExcessBaggageExists
    baseStation
    transactionId
  }
}
`;

const IDENTIFY_BAGGAGE = `
query identifyBaggage($identifyBaggageInput: IdentifyBaggageInput!) {
  identifyBaggage(identifyBaggageInput: $identifyBaggageInput) {
    isGetSuccessful,
    bookingReference,
    passengers {
      passengerID,
      sequenceNumber,
      issueBagTags {
        bagTagNumbers,
        bagTypes
      }
    }
  }
}
`;

const ADD_BAGGAGE_INFORMATION = `
mutation addBaggageInformation($addBaggageInformationInput: AddBaggageInformationInput!) {
  addBaggageInformation(addBaggageInformationInput: $addBaggageInformationInput) {
    isAddSuccessful
    errorMessages {
      code
      message
    }
    uniqueBagIdentifierPriorityList
    baggageReferenceList {
      boardPoint
      baggageReference
    }
    isStaffForceAcceptanceEnforced
    baseStation
    transactionId
  }
}`;

const GENERATE_TAG_NUMBER = `
query generateTagNumber($generateTagNumberInput: GenerateTagNumberInput!) {
  generateTagNumber(generateTagNumberInput: $generateTagNumberInput) {
    isGetSuccessful 
    bookingReference
    shortCheckinDestination
    shortCheckinDestinationCity
    errorMessages {
      code
      message
    }
    passengers {
      passengerID
      sequenceNumber
      issueBagTags {
        bagTagNumbers
        bagTypes
        uniqueBagIdentifier
        baggageReference
        issuingCarrierCode
        serialNumber
        bagType
        finalDestination
        issuingCarrierNumber
        bagGroupOwnerReferenceNumber
        individualBagOwnerReferenceNumber
        bagTagNumberPrefix
      }
    }
    baseStation
    transactionId
  }
}
`;

const PRINT_BOARDING_PASS = `
query boardingPassXmlData($getBoardingPassXMLDataInput: GetBoardingPassXMLDataInput!) {
  boardingPassXmlData(getBoardingPassXMLDataInput: $getBoardingPassXMLDataInput) {    
    isGetSuccessful
    errorMessages {
      code
      message
    }
    baseStation
    transactionId
    rePrintBPResponseList {
        passengerID
        passengerDID
        message
        status
        itineraryList {
          aircraftType
          airline
          arrivalDate
          departureDate
          departureGate
          arrivalTime
          departureTime
          flight
          passengerDetails {
            bookingClass
            firstName
            lastName
            passengerID
            bagCount
            seat
            cabin
            bookingClass
            bagCount
            boardingPassFlag
            checkInNumber
            departureGate
            destination
            lineNumber
            passengerType
            sSRCodeList
            freeTextInfoList
            frequentFlyerDetails {
              carrierCode
              number
              tierCode
              tierDescription
            }
            travelDocDataList { 
              travelDoc
              type
              pNGImage
              xMLDigitalSignatureData {
                barcodeInfo {
                  barcode
                  version
                }
                boardingPassSource
                boardingPassType
                boardingPassItineraryDetail {
                  boardingTime
                  bookingCode
                  cabin
                  cabinText
                  checkInSequence
                  clockType
                  deck
                  destination
                  destinationCity
                  destinationCountry
                  documentType
                  estimatedArrivalTime
                  estimatedDepartureDate
                  estimatedDepartureTime
                  gate
                  groupZone
                  julianDepartureDate
                  lounge
                  fareFamily
                  origin
                  originCity
                  originCountry
                  pingTip
                  premiumText
                  seatInfo {
                    column
                    row
                  }
                  terminal
                  ticketText
                  vCRNumber {
                    checkDigit
                    couponNumber
                    validatingCarrier
                    value
                    version
                  }                  
                }
              }
            }
            smokingRowFlag
          }
        }
      }
    }
  }  
`;

const MARK_BP_AS_PRINTED = `
mutation markBoardingPassAsPrinted($markBPAsPrintedInput: MarkBPAsPrintedInput!) {
  markBoardingPassAsPrinted(markBPAsPrintedInput: $markBPAsPrintedInput) {
    isUpdateSuccessful    
    numberOfRecordsUpdated
    errorMessages
  }
}
`;

const INITIATE_CUSTOMER_ACCEPTANCE = `
mutation initiateCustomerAcceptance($initiateCustomerAcceptanceInput: InitiateCustomerAcceptanceInput!) {
  initiateCustomerAcceptance(initiateCustomerAcceptanceInput: $initiateCustomerAcceptanceInput) {
    isCustomerAccepted
    isAPPErrorSeen
    errorMessages 
    passengerList {
      customerPrimeId
      productPrimeId
      securityNumber
      seatNumber
    }
    baseStation
    transactionId
  }
}
`;

const EDIT_CPR = `
mutation editCpr($editCprInput: EditCprInput!) {
  editCpr(editCprInput: $editCprInput) {
    isUpdateSuccessful
    errorMessages {
      code
      message
    }
    baseStation
    transactionId
    passengerTimaticChecks {
      passengerName
      timaticInfoStatus
      flightDeckMessage
      customerPrimeId
      productPrimeId
    }
  }
}
`;

const ACCEPT_BAGGAGE_GROUP = `
mutation acceptBaggageGroup($acceptBaggageGroupInput: AcceptBaggageGroupInput!) {
  acceptBaggageGroup(acceptBaggageGroupInput: $acceptBaggageGroupInput) {
    isGetSuccessful,
    totalWeight,
    unitQualifier
    uniqueBagIdentifierList
    uniqueBagIdentifierPriorityList
    errorMessages {
      code
      message
    }
    flightLevelErrorMessages
    baggageReference,
    isStaffForceAcceptanceEnforced
    baseStation
    transactionId
  }
}
`;

const GET_BAG_ALLOWANCE = `
mutation bagAllowance($getBagAllowanceInput: GetBagAllowanceInput!) {
  bagAllowance(getBagAllowanceInput: $getBagAllowanceInput) {
    isGetSuccessful,
    totalWeight,
    totalNumberOfBags,
    baggageDetailsList {
      number,
      weight,
      unitQualifier,
      passengerId
    }
    errorMessages
    poolGroupErrorMessages
    baseStation
    transactionId
  }
}
`;

const BOOKING_SEARCH = `
mutation searchBooking($bookingInput: BookingInput!) {
  searchBooking(bookingInput: $bookingInput) {
    baseStation
    transactionId
    excessBaggageId
    airlineCode
    airlineName
    bookingReference
    departureDate
    departureTime
    departureTerminal
    scheduledDepartureDate
    scheduledDepartureTime
    arrivalDate
    arrivalTime
    originCode
    destinationCode
    originCity
    destinationCity
    flightNumber
    eligibilityCheck
    segmentActionCode
    headPassengerId
    departureGate
    agentDutyCode
    boardingTime
    emailAddresses
    isPCRTestNotRecorded
    isBookingTriangularFlight
    passengers {
      isStaff
      bookingReference
      amadeusPassengerType
      bookingClass
      cabin
      passportIssuingCountry
      dateOfBirth
      passportNumber      
      passportExpiry
      emailAddresses
      firstName
      gender
      isCheckedIn
      isADCCheckComplete
      lastName
      nameRefNumber
      passengerID
      passengerDID
      passengerJID
      passengerType
      seatNumber
      segmentIndicator
      segmentStatus
      sequenceNumber
      staffPriorityCode
      isStaffStandBy
      standByIndicator
      ticketNumber
      title
      infantPassengerID
      emergencyContactName
      emergencyContactCountryCode
      emergencyContactPhone
      segments {
        actionCode
        airlineCode
        airlineName
        flightNumber
        departureCode
        departureCity
        departureDateTime
        standardDepartureDateTime
        arrivalCode
        arrivalCity
        arrivalDateTime
        flightDuration
        classOfService
        equipmentType
        marketingFlightNumber
        marketingAirlineCode
        referenceQualifier
        passengerDID
        seatNumber
        bookingClass
        departureGate
        departureTerminal
        deck
        sequenceNumber
        boardingTime
        isStandBy
        isSeatPurchased
      }
      baggage {
        unit
        allowance
        type
        pieceWeight
        numberOfBags
      }
      frequentFlyerInformation {
        airline
        tierName
        shortText
        tierLevelNumber
        number
      }
      passengerBaggageRoutes{
        airlineCode
        flightNumber
        departureCode
        passengerID
        passengerDID
        seatNumber
      }
      specialServiceRequests {
        actionCode
        airlineCode
        code
        freeText
        fullText
        type
      }      
    }
    segments {
      actionCode
      airlineCode
      airlineName
      flightNumber
      departureCode
      departureCity
      departureDateTime
      standardDepartureDateTime
      arrivalCode
      arrivalCity
      arrivalDateTime
      flightDuration
      classOfService
      equipmentType
      marketingFlightNumber
      marketingAirlineCode
      referenceQualifier
      passengerDID
      seatNumber
      bookingClass
      departureGate
      departureTerminal
      deck
      sequenceNumber
      boardingTime
    }
    dateTimeToNextOperatingFlight {
      days
      hours
      minutes
    }
    baggageReference
    uniqueBagIdentifiersList
    activatedBagTagsList {
      shortCheckinDestination
      shortCheckinDestinationCity
      bagTagNumber
      bagTypes
      uniqueBagIdentifier
      baggageReference
      issuingCarrierCode
      bagType
      finalDestination
      issuingCarrierNumber
      bagGroupOwnerReferenceNumber
      individualBagOwnerReferenceNumber
      bagTagNumberPrefix
      bagWeight
      bagTagStatus
    }
    inactiveBagTagsList {
      shortCheckinDestination
      shortCheckinDestinationCity
      bagTagNumber
      bagTypes
      uniqueBagIdentifier
      baggageReference
      issuingCarrierCode
      bagType
      finalDestination
      issuingCarrierNumber
      bagGroupOwnerReferenceNumber
      individualBagOwnerReferenceNumber
      bagTagNumberPrefix
      bagWeight
      bagTagStatus
    }
    bagGroupOwnerPassengerID
    bagGroupOfScannedPassenger
    allPassengersInAnyBagGroup
    isBaggageInfoForScannedPassenger
    totalNumberOfIssuedBagTags
    departureDateTime {
      year
      month
      day
      hour
      minutes
      seconds
    }
  }
}
`;

const ISSUE_BAG_TAGS = `
mutation issueBagTagsOptimal($issueBagTagsInputOptimal: IssueBagTagsInputOptimalInput!) {
  issueBagTagsOptimal(issueBagTagsInputOptimal: $issueBagTagsInputOptimal) {
    message
    status
    bookingReference
    shortCheckinDestination
    shortCheckinMessage
    shortCheckinDestinationCity
    flightInfos{
      airline
      flight
      origin
      destination
    }
    passengers {
      passengerID
      sequenceNumber
      issueBagTags {
        bagTagNumbers
        bagTypes
      }
    }
  }
}
`;

const ACTIVATE_BAG_TAG = `
mutation activateBagTagOptimal($activateBagTagInputOptimal: ActivateBagTagInputOptimalInput!) {
  activateBagTagOptimal(activateBagTagInputOptimal: $activateBagTagInputOptimal) {
    message
    passengerID
    status
  }
}
`;

const BARCODE_DATA = `
query barcodeData($rawString: String!) {
  barcodeData(rawString: $rawString) {
    pNR
  }
}
`;

const PAYMENT_CODE = `
mutation paymentCode($paymentCodeInput: PaymentCodeInput) {
  paymentCode(paymentCodeInput: $paymentCodeInput)
}
`;

const ADDITIONAL_BAGGAGE_INFO = `
query additionalBaggageInfo($paymentCode: String!) {
  additionalBaggageInfo(paymentCode: $paymentCode) {
    status
  }
}
`;

const REQUEST_OFFER = `
query requestOffer($plusgradeInput: PlusgradeInput!) {
  requestOffer(plusgradeInput: $plusgradeInput) {
    offerUrl
    responseStatus
    flights {
      carrierCode
      flightNumber
      products {
        eligible
        fixedPriceAmount
        ineligibleReason
        offerCurrencyCode
        productName
        productType
        upgradeType
        instantUpgrade {
          eligible
          ineligibleReason
          amount
          pointsAmount
        }
      }
    }
  }
}
`;

const TRAVEL_DOCUMENT = `
query travelDocument($mrz: [String]) {
  travelDocument(mrz: $mrz) {
    documentNumber
    issuingCountry
    type
    identity {
      firstAndLastNames
      fullName
    }
  }
}
`;

const TIMATIC_CHECK = `
mutation timaticCheck($timaticCheckInput: TimaticCheckInput) {
  timaticCheck(timaticCheckInput: $timaticCheckInput) {
    status
    message
    updateDocStatus
    updateDocMessage
    addDocVStatus
    addDocVMessage
    timRequestStatus
    timRequestMessage
    timaticInfo {
      status
      timaticCountryInfo {
        countryCode
        countryName
        status
        sections {
          name
          text
          type
          subSectionInfos {
            name
            text
            type
          }
        }
      }
    }
  }
}
`;

const TIMATIC_OVERRIDE = `
mutation timaticOverride($timaticCheckInput: TimaticCheckInput) {
  timaticOverride(timaticOverrideInput: $timaticCheckInput) {
    status
    message
  }
}
`;

const SEAT_MAP = `
query seatMap($seatMapInput: GetFDSeatMapInput!) {
  seatMap(seatMapInput: $seatMapInput) {
    isGetSuccessful,
    errorMessages,
    seatMaps {
       airline
       equipment
       firstName
       flightNumber
       lastName
       message
       status
       passengerIds
       cabin {
        class
        columns {
          characteristics
          columnName
        }
        firstRow
        lastRow
        location
        marketingDescription
        rows {
          number
          type
          rowCharacteristics
          rowFacility {
            facility {
              characteristics
              location
            }
            location
          }
          seat {
            blockCode
            chargeableInd
            column
            exitRowInd
            facilitiesDetail
            inoperativeInd
            limitationDetails
            locationDetails
            noInfantInd
            occupationDetail
            occupiedInd
            isWindowSeatWithoutWindow
            isCenterSeat
            isFrontOfCabin
            isLeftSideOfAircraft
            isSeatWithoutAMovieView
            isPreferentialSeat
            isRightSideOfAircraft
            isWindowAndAisleTogether
            offer {
              commercialName
              entitledInd
              premiumInd
              price {
                amount
                currency
              }
              restrictedReclineInd
              travellerID
            }
            seatNumber
          }
          type
        }
        seatOccupationDefault
       }
    }
  }
}
`;

const ALLOCATE_SEAT = `
mutation allocateSeat($input: AllocateSeatInput!) {
  allocateSeat(input: $input)
  {
    isDCSUpdateSuccess
    errorMessages
  }
}
`

const ANCILLARIES = `
mutation ancillaries($ancillaryOfferInput: AncillaryOfferInput) {
  ancillaries(ancillaryOfferInput: $ancillaryOfferInput) {
    message
    status
    airline
    vendor
    currency
    rFICCode
    eMDType
    ancillaryOffers{
      subCode
      price
      currency
      description
      feeApplicationIndicator
      refundIndicator
      interline
      commission
      taxAmount
      taxCode
    }
  }
}
`;

const INTEGRATED_CATALOGUE = `
query integratedCatalogue($integratedCatalogueInput: IntegratedCatalogueInput) {
  integratedCatalogue(integratedCatalogueInput: $integratedCatalogueInput) {
    isGetSuccessful
    errorMessages
    baseStation,
    transactionId
    ratesForWeightRangesList {
      ratePerKG
      currency
      minimumWeight
      maximumWeight
      description
    }
    ratesForStandardBagsList {
      bagNumber
      currency
      ratePerNthStandardBag
      description
    }
    ratesForHeavyBagsList {
      bagNumber
      currency
      ratePerNthHeavyBag
      description
    }
    excessHeavyBagsRate {
      currency
      ratePerExcessHeavyBag
      description
    }
  }
}
`;

const EXCESS_BAGGAGE_RATE = `
query excessBaggageRate($excessBaggageRateInput: ExcessBaggageRateInput) {
  excessBaggageRate(excessBaggageRateInput: $excessBaggageRateInput) {
    isGetSuccessful,
    currency,
    errorMessages,
    excessCharges,
    baseStation,
    transactionId
  }
}
`;

const CHECK_IN = `
mutation checkInPassenger($checkInInput: CheckInInput) {
  checkInPassenger(checkInInput: $checkInInput) {
    message
    status
    itineraryList{
      aircraftType
      airline
      departureDate
      departureGate
      departureTime
      flight
      passengerDetails{
        bookingClass
        firstName
        lastName
        passengerID
        bagCount
        seat
        cabin
        bookingClass
        bagCount
        boardingPassFlag
        checkInNumber
        departureGate
        destination
        lineNumber
        passengerType
        sSRCodeList
        freeTextInfoList
        travelDocDataList{
          travelDoc
          type
          pNGImage
          xMLDigitalSignatureData{
            barcodeInfo{
              barcode
              version
            }
            boardingPassSource
            boardingPassType
            boardingPassItineraryDetail{
              boardingTime
              bookingCode
              cabin
              cabinText
              checkInSequence
              clockType
              deck
              destination
              destinationCity
              destinationCountry
              documentType
              estimatedArrivalTime
              estimatedDepartureDate
              estimatedDepartureTime
              gate
              groupZone
              julianDepartureDate
              origin
              originCity
              originCountry
              pingTip
              premiumText
              seatInfo{
                column
                row
              }
              terminal
              ticketText
              vCRNumber{
                checkDigit
                couponNumber
                validatingCarrier
                value
                version
              }
            }
          }
        }
        smokingRowFlag
      }
    }
    pecTabDataList{
      version
      data
    }
  }
}`;

const EMERGENCY_CONTACT = `
mutation addEmergencyContact($emergencyContactInput: TimaticCheckInput) {
  addEmergencyContact(emergencyContactInput: $emergencyContactInput)
  {
    status
    message
  }
}`;

const CREATEPAYMENT = `
mutation createPaymentTransaction($paymentTransactionInput: PaymentTransactionInput) {
  createPaymentTransaction(paymentTransactionInput: $paymentTransactionInput) {
    message
    currency
    guid
    currentStatus
  }
}
`;

const GETPAYMENT = `
query paymentTransaction($guid: String!) {
  paymentTransaction(guid: $guid) {
    guid
    currency
    currentStatus
    bookingReference
    errorMessage
    totalPrice
    paymentTransactionItem{
      description
      quantity
      totalPrice
      serviceGroup
      eMDNumber
    }
  }
}
`;

const BOARDINGPASS_REPRINT = `
mutation rePrintBP($rePrintBPInput: RePrintBPInput){
  rePrintBP(rePrintBPInput: $rePrintBPInput)
  {
    message
    status
    itineraryList{
      aircraftType
      airline
      departureDate
      departureGate
      departureTime
      flight
      passengerDetails{
        bookingClass
        firstName
        lastName
        passengerID
        bagCount
        seat
        cabin
        bookingClass
        bagCount
        boardingPassFlag
        checkInNumber
        departureGate
        destination
        lineNumber
        passengerType
        sSRCodeList
        freeTextInfoList
        travelDocDataList{
          travelDoc
          type
          pNGImage
          xMLDigitalSignatureData{
            barcodeInfo{
              barcode
              version
            }
            boardingPassSource
            boardingPassType
            boardingPassItineraryDetail{
              boardingTime
              bookingCode
              cabin
              cabinText
              checkInSequence
              clockType
              deck
              destination
              destinationCity
              destinationCountry
              documentType
              estimatedArrivalTime
              estimatedDepartureDate
              estimatedDepartureTime
              gate
              groupZone
              julianDepartureDate
              origin
              originCity
              originCountry
              pingTip
              premiumText
              seatInfo{
                column
                row
              }
              terminal
              ticketText
              vCRNumber{
                checkDigit
                couponNumber
                validatingCarrier
                value
                version
              }
            }
          }
        }
        smokingRowFlag
      }
    }
  }
}`;

const EMAIL_RESERVATION = `
mutation sendEmailReservation($reservationInput: ReservationInput!) {
  sendEmailReservation(reservationInput: $reservationInput)
  {
    message
    status
  }
}`;

const UPDATE_EMAILADDRESS = `
mutation updateEmailAddress($updateEmailInput: UpdateEmailInput!) {
  updateEmailAddress(updateEmailInput: $updateEmailInput)
  {
    message
    status
  }
}`;

const PRICE_UNPAID_ITEMS = `
mutation priceUnpaidItems($priceUnpaidItemsInput: PriceUnpaidItemsInput!) {
  priceUnpaidItems(priceUnpaidItemsInput: $priceUnpaidItemsInput)
    {
    isGetSuccessful
    errorMessages,
    baseStation,
    transactionId,
    unpaidItems {
      passengerID  
      pricingRecordId
      pricingRecordStatus
      price
      currency
      excessBaggageAmount
      excessBaggageUnit
      description
      serviceType
      serviceValue
      surname
    }
  }
}`;

const ISSUE_PAYMENT_DOCUMENTS = `
mutation issuePaymentDocument($issuePaymentDocumentInput: IssuePaymentDocumentInput!) {
  issuePaymentDocument(issuePaymentDocumentInput: $issuePaymentDocumentInput)
  {
    issuePaymentDocuments {
      surname
      id
      documentId
      messages
      amount
      currencyCode
    }
    statusCode
    baseStation
    transactionId
  }
}`;

const EMAIL_DOCUMENT_RECEIPTS = `
query documentReceipt($documentReceiptInput: GetDocumentReceiptInput!) {
  documentReceipt(documentReceiptInput: $documentReceiptInput)
  {
    isSuccessful
    statusCode
    baseStation
    transactionId
  }
}`;

class SwitchboardClient {
  /**
   * Creates a fetchQL instance.
   */
  constructor() {
    this.fetchQL = new FetchQL(get(switchboardUri));
  }
  /**
   * Wraps FetchQL's query().
   *
   * Sets the URL and X-API-Key header based on the latest from the store.
   * @returns {promise} A promise for the graphql query, as per FetchQL.
   */
  query(...args) {
    this.fetchQL.url = get(switchboardUri);
    this.fetchQL.requestObject.headers = {
      ...this.fetchQL.requestObject.headers,
      'X-API-Key': get(switchboardApiKey),
      'X-Transaction-Id': get(transactionId),
    };
    return this.fetchQL.query(...args);
  }

  async acceptBaggageGroup(
    inputPassengerList,
    inputBaggageRouteList,
    totalNumberOfBags,
    totalWeightAllowed,
    departureDateTime,
    isStaffForceAcceptanceRequired,
    baseStation) {
    const queryParam = {
      operationName: 'acceptBaggageGroup',
      query: ACCEPT_BAGGAGE_GROUP,
      variables: {
        acceptBaggageGroupInput:
          buildAcceptBaggageGroupInput(
            inputPassengerList,
            inputBaggageRouteList,
            totalNumberOfBags.toString(),
            totalWeightAllowed.toString(),
            departureDateTime,
            isStaffForceAcceptanceRequired,
            baseStation
          )
      },
    };

    return this.query(queryParam).then((response) => {
      return response;
    });
  }

  async getBagAllowance(inputPassengerList) {
    const queryParam = {
      operationName: 'bagAllowance',
      query: GET_BAG_ALLOWANCE,
      variables: {
        getBagAllowanceInput: buildGetBagAllowanceInput(inputPassengerList)
      },
    };
    return this.query(queryParam).then((response) => {
      return response;
    });
  }

  async updateBaggageInformation(inputUpdateBaggageInformation) {
    const queryParam = {
      operationName: 'updateBaggageInformation',
      query: UPDATE_BAGGAGE_INFORMATION,
      variables: {
        updateBaggageInformationInput: inputUpdateBaggageInformation
      }
    };

    return this.query(queryParam);
  }
  
  async overrideExcessBaggage(inputOverrideExcessBaggage) {
    const queryParam = {
      operationName: 'overrideExcessBaggage',
      query: OVERRIDE_EXCESS_BAGGAGE,
      variables: {
        overrideExcessBaggageInput: inputOverrideExcessBaggage
      }
    };

    return this.query(queryParam);
  }

  async emailEMDIssuanceFailed(emdIssuanceFailureEmailDetails) {
    const queryParam = {
      operationName: 'emailEMDIssuanceFailed',
      query: EMAIL_EMD_ISSUANCE_FAILED,
      variables: {
        emailDetails: emdIssuanceFailureEmailDetails
      }
    };

    return this.query(queryParam);
  }

  async identifyBaggage(passengerList, bookingReference) {
    const queryParam = {
      operationName: 'identifyBaggage',
      query: IDENTIFY_BAGGAGE,
      variables: {
        identifyBaggageInput: {
          passengerList,
          bookingReference
        }
      }
    };

    return this.query(queryParam).then((response) => {
      return response;
    });
  }

  async addBaggageInformation(
    totalNumberOfIssuedBagTags,
    baggageReference,
    numberOfBags,
    flightDetails,
    bookingReference,
    passengerType,
    surname,
    customerPrimeId,
    departureDateTime,
    isStaffForceAcceptanceRequired,
    baseStation,
    isStaff) {
    const queryParam = {
      operationName: 'addBaggageInformation',
      query: ADD_BAGGAGE_INFORMATION,
      variables: {
        addBaggageInformationInput: {
          totalNumberOfIssuedBagTags: totalNumberOfIssuedBagTags.toString(),
          baggageReference: baggageReference,
          boardPoint: flightDetails.boardPoint,
          offPoint: flightDetails.offPoint,
          bookingReference: bookingReference,
          departureDate: flightDetails.departureDate,
          flightNumber: flightDetails.flightNumber,
          marketingCarrier: flightDetails.marketingCarrier,
          numberOfBags: numberOfBags.toString(),
          productPrimeId: flightDetails.productPrimeId,          
          passengerType: passengerType,
          surname: surname,
          customerPrimeId: customerPrimeId,
          departureDateTime: departureDateTime,
          isStaffForceAcceptanceRequired: isStaffForceAcceptanceRequired,
          baseStation: baseStation,
          isStaff: isStaff,
          transactionId: get(transactionId)
        }
      }
    };

    return this.query(queryParam).then((response) => {
      return response;
    });
  }

  async generateTagNumber(flightDetails, bookingReference, passengerList, baggageReference) {
    const queryParam = {
      operationName: 'generateTagNumber',
      query: GENERATE_TAG_NUMBER,
      variables: {
        generateTagNumberInput: {
          numberOfBags: flightDetails.numberOfBags.toString(),
          marketingCarrier: flightDetails.marketingCarrier,
          flightNumber: flightDetails.flightNumber,
          departureDate: flightDetails.departureDate,
          boardPoint: flightDetails.boardPoint,
          productPrimeId: flightDetails.productPrimeId,
          bookingReference: bookingReference,
          passengerList: passengerList,
          baggageReference: baggageReference,
          baseStation: baseStationCode,
          transactionId: get(transactionId)
        }
      }
    };

    return this.query(queryParam).then((response) => {
      return response;
    });
  }

  async initiateCustomerAcceptance(passengerList) {
    const queryParam = {
      operationName: 'initiateCustomerAcceptance',
      query: INITIATE_CUSTOMER_ACCEPTANCE,
      variables: {
        initiateCustomerAcceptanceInput: {
          passengerList,
          baseStation: baseStationCode,
          transactionId: get(transactionId)
        }
      }
    };

    return this.query(queryParam).then((response) => {
      return response;
    });
  }


  async editCPR(passengerDetailsList) {
    const queryParam = {
      operationName: 'editCpr',
      query: EDIT_CPR,
      variables: {
        editCprInput: passengerDetailsList
      },
    };

    return this.query(queryParam).then((response) => {
      return response;
    });
  }

  async bookingSearch(input) {
    const { pnr, mrz, barcode } = input;
    const queryParam = {
      operationName: 'searchBooking',
      query: BOOKING_SEARCH,
      variables: {
        bookingInput: {
          baseStation: baseStationCode,
        },
      },
    };

    if (pnr) {
      queryParam.variables.bookingInput.inputCode = pnr;
      queryParam.variables.bookingInput.inputCodeType = 'PNR_LOCATOR';
    } else if (mrz) {
      queryParam.variables.bookingInput.inputCode = mrz;
      queryParam.variables.bookingInput.inputCodeType = 'MRZ_CODE';
    } else if (barcode) {
      queryParam.variables.bookingInput.inputCode = barcode;
      queryParam.variables.bookingInput.inputCodeType = 'BAR_CODE';
    }

    return this.query(queryParam).then((response) => {
      transactionId.set(
        safeObjectAttribute(response, 'data.searchBooking.transactionId'),
      );
      return response;
    });
  }
  
  async printBoardingPass(boardingPassDetails) {
    const queryParam = {
      operationName: 'boardingPassXmlData',
      query: PRINT_BOARDING_PASS,
      variables: {
        getBoardingPassXMLDataInput: boardingPassDetails
      }
    };

    return this.query(queryParam).then((response) => {
      return response;
    });
  }
  
  async markBoardingPassAsPrinted(passengerList) {
    const queryParam = {
      operationName: 'markBoardingPassAsPrinted',
      query: MARK_BP_AS_PRINTED,
      variables: {
        markBPAsPrintedInput: {
          passengerList
        }
      }
    };

    return this.query(queryParam).then((response) => {
      return response;
    });
  }

  async issuePaymentDocument(priceUnpaidItemsData) {
    const queryParam = {
      operationName: 'issuePaymentDocument',
      query: ISSUE_PAYMENT_DOCUMENTS,
      variables: {
        issuePaymentDocumentInput: buildIssuePaymentDocumentInput(priceUnpaidItemsData)
      }
    };

    return this.query(queryParam).then((response) => {
      return response;
    });
  }

  /**
   * Decode a barcode.
   *
   * `rawString` The raw unicode string from CUSS.
   */
  async barcode(rawString) {
    return this.query({
      operationName: 'barcodeData',
      query: BARCODE_DATA,
      variables: {
        rawString,
      },
    });
  }

  /** Issue Bag Tags
   *
   *  Issue bag tags given a booking, passenger IDs and a number of bags
   *  for each passenger.
   *
   * @param {object} booking - Booking object from Switchboard
   * @param {object} passenger - Passenger object from Switchboard
   * @param {number} bagCount - Number of bags to issue tags for.
   */
  async issueBagTags(booking, passenger, bagCount) {
    return this.query({
      operationName: 'issueBagTagsOptimal',
      query: ISSUE_BAG_TAGS,
      variables: {
        issueBagTagsInputOptimal: buildIssueBagTagsInput(
          booking,
          passenger,
          bagCount,
        ),
      },
    });
  }

  /**
 * Activate Optimal Bag Tag
 * @param {object} booking - Booking object from Switchboard.
 * @param {object} passenger - Passenger object from Switchboard.
 * @param {object} bag - bag Object.
 */
  async activateBagTag(booking, passenger, bag) {
    return this.query({
      operationName: 'activateBagTagOptimal',
      query: ACTIVATE_BAG_TAG,
      variables: {
        activateBagTagInputOptimal: buildActivateBagTagInput(
          booking,
          passenger,
          bag,
        ),
      },
    });
  }

  /** Get Payment Code
   *
   *  Get Bag Over Allowance
   *
   * @param {string} bookingReference The string
   * @param {number} price The Number
   * @param {number} weight The Number
   */
  async paymentCode(bookingReference, price, weight) {
    return this.query({
      operationName: 'paymentCode',
      query: PAYMENT_CODE,
      variables: {
        paymentCodeInput: {
          bookingReference,
          price,
          weight,
        },
      },
    });
  }

  /**
   * Retrieve additionalBaggageInfo data for a paymentCode.
   *
   * @param {string} paymentCode The string
   */
  async additionalBaggageInfo(paymentCode) {
    return this.query({
      operationName: 'additionalBaggageInfo',
      query: ADDITIONAL_BAGGAGE_INFO,
      variables: {
        paymentCode,
      },
    });
  }

  /**
   * Retreive the plusgrade offer.
   *
   * @param {object} booking - The booking object from switchboard.
   * @param {object} passenger - The passenger object from switchboard.
   * @returns {promise} A promise that resolves when the request has finished.
   */
  async requestOffer(lastName, pnr) {
    return this.query({
      operationName: 'requestOffer',
      query: REQUEST_OFFER,
      variables: {
        plusgradeInput: {
          lastName,
          pnr,
        },
      },
    });
  }

  /**
   * Decode an MRZ.
   *
   * @param {string[]} mrz The MRZ as an array of strings.
   */
  async travelDocument(mrz) {
    return this.query({
      operationName: 'travelDocument',
      query: TRAVEL_DOCUMENT,
      variables: {
        mrz,
      },
    });
  }

  /**
   * Perform a Timatic Check.
   *
   * @param {object} booking - The booking object from switchboard.
   * @param {object} passenger - A passenger object from switchboard.
   * @param {string[]} mrz - The MRZ as an array of strings.
   * @returns {promise}
   */
  async timaticCheck(booking, passenger, infant, mrz, infantMrz) {
    return this.query({
      operationName: 'timaticCheck',
      query: TIMATIC_CHECK,
      variables: {
        timaticCheckInput: buildTimaticCheckInput(
          booking,
          passenger,
          infant,
          mrz,
          infantMrz,
        ),
      },
    });
  }

  /**
   * Perform a Timatic Override.
   *
   * @param {object} booking - The booking object from switchboard.
   * @param {object} passenger - A passenger object from switchboard.
   * @param {string[]} mrz - The MRZ as an array of strings.
   * @returns {promise}
   */
  async timaticOverride(booking, passenger, mrz, infantMrz) {
    return this.query({
      operationName: 'timaticOverride',
      query: TIMATIC_OVERRIDE,
      variables: {
        timaticCheckInput: buildTimaticCheckInput(
          booking,
          passenger,
          mrz,
          infantMrz,
        ),
      },
    });
  }

  /**
   * Retreive the seat map.
   *
   * @param {object} booking - The booking store.
   * @param {object} flightNumber - The flightNumber object from switchboard.
   * @param {object} passenger - The passenger object from switchboard.
   * @returns {promise} A promise that resolves when the request has finished.
   */
  async seatMap(booking) {
    return this.query({
      operationName: 'seatMap',
      query: SEAT_MAP,
      variables: {
        seatMapInput: buildSeatMapInput(booking),
      },
    });
  }

  /**
   * Perform a seat change.
   *
   * @param {object} booking - The booking object from switchboard.
   * @param {object} passenger - The passenger object from switchboard.
   * @param {string} assignedSeat - The seat number of the passenger’s current seat.
   * @param {string} requestedSeat - The seat number of the passenger’s requested seat.
   * @returns {promise} A promise that resolves when the request has finished.
   */
  async allocateSeat(booking, passenger, assignedSeat, requestedSeat, amount) {
    return this.query({
      operationName: 'allocateSeat',
      query: ALLOCATE_SEAT,
      variables: {
        input: buildChangeSeatInput(
          booking,
          passenger,
          assignedSeat,
          requestedSeat,
          amount
        ),
      },
    });
  }

  /**
   * Add emergency contact info.
   *
   * @param {object} booking - The booking object from switchboard.
   * @param {object} passenger - A passenger object from switchboard.
   * @param {object} emergencyContact - Emergency contact object.
   * @returns {promise}
   */
  async emergencyContact(booking, passenger, emergencyContact) {
    return this.query({
      operationName: 'addEmergencyContact',
      query: EMERGENCY_CONTACT,
      variables: {
        emergencyContactInput: buildEmergencyContactInput(
          booking,
          passenger,
          emergencyContact,
        ),
      },
    });
  }

  /**
   * Perform a Ancillary Offer.
   *
   * @param {object} booking - The booking object from switchboard.
   * @param {object} currencyCode - The currencyCode object from switchboard.
   * @param {object} bookingClass - The bookingClass object from switchboard.
   * @returns {promise}
   */
  async ancillaries(booking, currencyCode, bookingClass) {
    return this.query({
      operationName: 'ancillaries',
      query: ANCILLARIES,
      variables: {
        ancillaryOfferInput: buildAncillaryOfferInput(
          booking,
          currencyCode,
          bookingClass,
        ),
      },
    });
  }

    /**
   * Retrieve excess baggage rates for piece concept
   *
   * @param {string} marketingCarrier - marketingCarrier.
   * @param {string} departureDate - departureDate.
   * @param {string} boardPoint - boardPoint.
   * @param {string} offPoint - offPoint.
   * @param {string} currency - currency
   * @param {string} numberOfBags - numberOfBags
   * @returns {promise}
   */
    async excessBaggageRate(marketingCarrier, departureDate, boardPoint, offPoint, customerPrimeId, numberOfBags) {
      return this.query({
        operationName: 'excessBaggageRate',
        query: EXCESS_BAGGAGE_RATE,
        variables: {
          excessBaggageRateInput: {
            marketingCarrier,
            departureDate,
            boardPoint,
            offPoint,
            customerPrimeId,
            numberOfBags,
            baseStation: baseStationCode,
            transactionId: get(transactionId)
          },
        },
      });
    }

  /**
   * Retrieve excess baggage rates by using integrated-catalogue.
   *
   * @param {string} bookingReference - Booking PNR.
   * @returns {promise}
   */
  async integratedCatalogue(bookingReference) {
    return this.query({
      operationName: 'integratedCatalogue',
      query: INTEGRATED_CATALOGUE,
      variables: {
        integratedCatalogueInput: {
          bookingReference,
          baseStation: baseStationCode,
          transactionId: get(transactionId)
        },
      },
    });
  }

  /**
   * Perform a Create Payment Transaction.
   *
   * @param {string} bookingReference
   * @param {string} currency
   * @param {List} paymentItems
   * @returns {promise}
   */
  async createPaymentTransaction(currency, bookingReference, paymentItems) {
    return this.query({
      operationName: 'createPaymentTransaction',
      query: CREATEPAYMENT,
      variables: {
        paymentTransactionInput: {
          currency,
          bookingReference,
          paymentTransactionItemsInput: paymentItems.map((paymentItem) =>
            paymentItem.getSwitchboardObject(),
          ),
        },
      },
    });
  }

  /**
   * I think this function is not used in Amadeus 
   * Perform a Get Payment Transaction.
   *
   * @param {string} guid The string
   */
  async paymentTransaction(guid) {

    return this.query({
      operationName: 'paymentTransaction',
      query: GETPAYMENT,
      variables: {
        guid,
      },
    });
  }

  /**
   * Check in passengers.
   *
   * @param {object} booking - The booking store.
   * @param {object} segment - The Switchboard Segment object.
   * @param {object[]} passenger - The Switchboard passenger objects.
   * @returns {promise} A Promise that resolves when the request returns.
   */
  async checkInPassenger(booking, passenger) {
    return this.query({
      operationName: 'checkInPassenger',
      query: CHECK_IN,
      variables: {
        checkInInput: buildCheckInInput(booking, passenger),
      },
    });
  }

  /**
   * Perform reprint Boarding Pass for already checked-in passenger.
   *
   * @param {object} booking - The booking store.
   * @param {object} segment - The Segment object from Switchboard.
   * @param {object} passenger - The Passenger object from Switchboard.
   * @returns {promise}
   */
  async reprintBP(booking, segment, passenger) {
    return this.query({
      operationName: 'rePrintBP',
      query: BOARDINGPASS_REPRINT,
      variables: {
        rePrintBPInput: buildReprintBPInput(booking, segment, passenger),
      },
    });
  }

  /**
   * Perform update email address to switchboard.
   *
   * @param {string} bookingReference - Booking reference from switchboard.
   * @param {string} newEmailAddress - Email address from onscreen keyboard.
   * @param {object} headPassengerManager - Booking reference from switchboard.
   *
   * @returns {promise}
   */
  async updateEmailAddress(
    bookingReference,
    newEmailAddress,
    headPassengerManager,
  ) {
    return this.query({
      operationName: 'updateEmailAddress',
      query: UPDATE_EMAILADDRESS,
      variables: {
        updateEmailInput: buildUpdateEmailInput(
          bookingReference,
          newEmailAddress,
          headPassengerManager,
        ),
      },
    });
  }

  /**
   * Perform an Email Sending Reservation.
   *
   * @param {object} booking - The booking store.
   * @param {object} headPassengerManager.
   * @param {object} checkInPassengersManager.
   * @param {object} receipt - The receipt store.
   * @param {string} currencyCode - Currency code.
   * @param {string} languageCode - Language code
   * @param {string} recipientEmail - The email address of the recipient.
   * @returns {promise}
   */
  async sendEmailReservation(
    booking,
    headPassengerManager,
    checkInPassengersManager,
    receipt,
    currencyCode,
    languageCode,
    recipientEmail,
  ) {
    // testing this file...

    return this.query({
      operationName: 'sendEmailReservation',
      query: EMAIL_RESERVATION,
      variables: {
        reservationInput: buildReservationInput(
          booking,
          headPassengerManager,
          checkInPassengersManager,
          receipt,
          currencyCode,
          languageCode,
          recipientEmail,
        ),
      },
    });
  }

  /**
   * Perform price unpaid Items to switchboard.
   *
   * @param {object} bookingDetails - bookingDetails from switchboard.
   * @returns {promise}
   */
  async priceUnpaidItems(bookingDetails, isBaggagePayment) {
    return this.query({
      operationName: 'priceUnpaidItems',
      query: PRICE_UNPAID_ITEMS,
      variables: {
        priceUnpaidItemsInput: buildPriceUnpaidItemsInput(
          bookingDetails,
          isBaggagePayment
        ),
      },
    });
  }

  /**
   * Perform email document receipt to switchboard.
   *
   * @param {string} email - head passenger email address.
   * @param {string} documentId - EMD number from issuePaymentDocument
   */
  async emailDocumentReceipts(email, documentId) {
    return this.query({
      operationName: 'documentReceipt',
      query: EMAIL_DOCUMENT_RECEIPTS,
      variables: {
        documentReceiptInput: {
          email: email,
          documentId: documentId,
          baseStation: baseStationCode,
          transactionId: get(transactionId)
        },
      },
    });
  }
}

function buildAcceptBaggageGroupInput(
  inputPassengerList,
  inputBaggageRouteList,
  totalNumberOfBags,
  totalWeightAllowed,
  departureDateTime,
  isStaffForceAcceptanceRequired,
  baseStation) {
  
    let passengerList = [];
  inputPassengerList.forEach(passenger => {
    let passengerDetails = {
      isStaff: passenger.isStaff,
      surname: passenger.surname,
      type: passenger.type,
      customerPrimeId: passenger.customerPrimeId,
      productDetailsList: passenger.productDetailsList
    }
    passengerList.push(passengerDetails);
  });

  let baggageRoutesList = [];
  inputBaggageRouteList.forEach(route => {
    let baggageRoute = {
      marketingCarrier: route.productFlightDetails.marketingCarrier,
      flightNumber: route.productFlightDetails.flightNumber,
      departureDate: route.productFlightDetails.departureDate,
      boardPoint: route.productFlightDetails.boardPoint,
      offPoint: route.productFlightDetails.offPoint
    }
    baggageRoutesList.push(baggageRoute);
  });

  return {
    baggageRoutesList,
    passengerList,
    totalNumberOfBags,
    totalWeightAllowed,
    departureDateTime,
    isStaffForceAcceptanceRequired,
    baseStation,
    transactionId: get(transactionId)
  }
}

/**
 * Create the BuildGetBagAllowanceInput object for SwitchBoard.
 *
 * @param {object} getBagAllowance - Switchboard getBagAllowanceInput object. 
 * @returns {Array} passengerList - Array of passengerList.
 */
function buildGetBagAllowanceInput(inputPassengerList) {
  let passengerList = [];
  inputPassengerList.forEach(passenger => {
    let passengerDetails = {
      surname: passenger.surname,
      customerPrimeId: passenger.customerPrimeId,
      marketingCarrier: passenger.marketingCarrier,
      flightNumber: passenger.flightNumber,
      departureDate: passenger.departureDate,
      boardPoint: passenger.boardPoint,
      productPrimeId: passenger.productPrimeId
    }
    passengerList.push(passengerDetails);
  });
  return {
    passengerList,
    baseStation: baseStationCode,
    transactionId: get(transactionId)
  }
}

/**
 * Create the AncillaryOfferInput object for SwitchBoard.
 *
 * @param {object} booking - Switchboard booking object.
 * @param {string} currency - Switchboard currency object.
 */
function buildAncillaryOfferInput(booking, currency, bookingClass) {
  return {
    agentCurrencyCode: currency,
    airline: booking.airlineCode,
    flightNumber: booking.flightNumber,
    departureAirport: booking.originCode,
    departureDate: booking.departureDate,
    departureTime: booking.departureTime
      .replace('PM', ':00')
      .replace('AM', ':00'),
    arrivalAirport: booking.destinationCode,
    arrivalDate: booking.arrivalDate,
    arrivalTime: booking.arrivalTime.replace('PM', ':00').replace('AM', ':00'),
    classOfService: bookingClass,
  };
}

/**
 * Create PassengerBagsInputOptimalInput
 *
 * @param {object} booking - Booking object from Switchboard.
 * @param {object} passenger - Passenger object from Switchboard.
 * @param {number} bagCount - Number of bag tags to issue.
 */
function buildIssueBagTagsInput(booking, passenger, bagCount) {
  return {
    bookingReference: booking.bookingReference,
    airline: booking.airlineCode,
    flight: booking.flightNumber,
    departureDate: booking.departureDate,
    origin: booking.originCode,
    segmentActionCode: booking.segmentActionCode,
    passengers: [
      {
        passengerID: passenger.passengerID,
        lastName: passenger.lastName,
        cabin: passenger.cabin,
        frequentFlyerTierNumber:
          (passenger.frequentFlyerInformation || {}).tierLevelNumber || null,
        baggageRequestCount: bagCount,
      },
    ],
  };
}

/**
 * Create buildActivateBagTagInput
 *
 * @param {object} booking - Booking object from Switchboard.
 * @param {object} passenger - Passenger object from Switchboard.
 * @param {object} bag - bag Object.
 */
function buildActivateBagTagInput(booking, passenger, bag) {
  return {
    bagTagNumber: bag.bagTagID,
    passengerID: passenger.passengerID,
    weight: bag.weight > 0 ? bag.weight : 1,
    airline: booking.airlineCode,
    flight: booking.flightNumber,
    departureDate: booking.departureDate,
    origin: booking.originCode,
  };
}

/**
 * Create the timaticCheckInput object for Switchboard.
 *
 * @param {object} booking - Switchboard booking object.
 * @param {object} passenger - Switchboard passenger object.
 * @param {string[]} mrz - The MRZ as an array of strings.
 * @returns {object} timaticCheckInput for Switchboard.
 */
function buildTimaticCheckInput(booking, passenger, mrz, infantMrz) {
  return {
    airlineCode: booking.airlineCode,
    bookingReference: booking.bookingReference,
    departureDate: booking.departureDate,
    flightNumber: booking.flightNumber,
    originCode: booking.originCode,
    passenger: {
      lastName: passenger.lastName,
      passengerID: passenger.passengerID,
      passengerType: passenger.passengerType,
    },
    mRZ: mrz,
    mRZ_Infant: infantMrz,
  };
}

/**
 * Create SeatMapInput object for Switchboard.
 *
 * @param {object} booking - Switchboard booking object.
 * @param {object} flightNumber - Switchboard flightNumber object.
 * @param {object} passenger - Switchboard passenger object.
 * @returns {object} SeatMapInput object for Switchboard.
 */
function buildSeatMapInput(booking) {

  // Top level query object
  const getPassengerSeatMapQuery = {passengerSeatMapList: []}

  // For each pax, get their segment details as input
  let bookingData = get(booking)

  bookingData.passengers.forEach(pax => {
    const passengerSeatMapList = []
    pax.segments.forEach(segment => {

      let flightLegSeatMapInput = {
        productPrimeId: segment.passengerDID,
        marketingCarrier: segment.airlineCode,
        flightNumber: segment.flightNumber,
        departureDate: segment.departureDateTime,
        boardPoint: segment.departureCode
      }

      passengerSeatMapList.push(flightLegSeatMapInput)
    })

    let passengerSeatMap = 
    {
      surname: pax.lastName,
      firstName: pax.firstName,
      primeFlightLegSeatMaps: passengerSeatMapList
    }

    getPassengerSeatMapQuery.passengerSeatMapList.push(passengerSeatMap)
  })

  getPassengerSeatMapQuery.baseStation = baseStationCode;
  getPassengerSeatMapQuery.transactionId = get(transactionId);

  return getPassengerSeatMapQuery;
}
/**
 * Create SeatMapInput object for Switchboard.
 *
 * @param {object} booking - Switchboard booking object.
 * @param {object} passenger - Switchboard passenger object.
 * @param {string} flightNumber - The flightNumber.
 * @param {string} requestedSeat - The seat number of the passenger’s requested seat.
 * @returns {object} SeatMapInput object for Switchboard.
 */
function buildChangeSeatInput(
  booking,
  passenger,
  flightNumber,
  requestedSeat,
  amount
) {
  return {
    boardPoint: booking.getDepartureCode(flightNumber),
    offPoint: get(booking).destinationCode,
    departureDate: booking.getDepartureDateAsDDMMYY(flightNumber),
    departureDateEightCharacters: booking.getDepartureDate(flightNumber),
    flightNumber: flightNumber,
    productPrimeId: passenger.passengerDID,
    seatNumber: requestedSeat,
    marketingCarrier: get(booking).airlineCode,
    amount: amount,
    baseStation: baseStationCode,
    transactionId: get(transactionId)
  };
}

/**
 * Create the emergencyContactInput object for Switchboard.
 *
 * @param {object} booking - Switchboard booking object.
 * @param {object} passenger - Switchboard passenger object.
 * @param {object} emergencyContact - Emergency contact details object.
 * @returns {object} emergencyContactInput for Switchboard.
 */
function buildEmergencyContactInput(booking, passenger, emergencyContact) {
  return {
    airlineCode: booking.airlineCode,
    bookingReference: booking.bookingReference,
    departureDate: booking.departureDate,
    flightNumber: booking.flightNumber,
    originCode: booking.originCode,
    passenger: {
      lastName: passenger.lastName,
      passengerID: passenger.passengerID,
    },
    emergencyContact: {
      emergencyContactName: emergencyContact.name,
      emergencyContactCountryCode: emergencyContact.countryCode,
      emergencyContactPhone: emergencyContact.phone,
    },
  };
}

/**
 * Build the checkInPassengerInput object for Switchboard.
 *
 * @param {object} booking - The Svelte booking store.
 * @param {object[]} passenger - Switchboard passenger object.
 * @returns {object} CheckInPassengerInput for Switchboard.
 */
function buildCheckInInput(booking, passenger) {
  return {
    airline: get(booking).airlineCode,
    bookingReference: get(booking).bookingReference,
    bookingClass: booking.getBookingCode(passenger, booking.flightNumber),
    departureDate: get(booking).departureDate,
    flight: get(booking).flightNumber,
    origin: get(booking).originCode,
    passengers: [
      {
        lastName: passenger.lastName,
        passengerId: passenger.passengerID,
        gender: booking.getPassengerGender(passenger),
      },
    ],
  };
}

/**
 * Build the reprintInput object for reprint boarding pass Service.
 * @param {object} booking - Booking store.
 * @param {object} segment - Switchboard segment object.
 * @param {object} passenger - Switchboard passenger object
 */
function buildReprintBPInput(booking, segment, passenger) {
  return {
    airline: segment.airlineCode,
    departureDate: dateTimeToDate(segment.departureDateTime),
    destination: segment.arrivalCode,
    flight: segment.flightNumber,
    origin: segment.departureCode,
    lastName: passenger.lastName,
    passengerId: booking.getPassengerID(passenger, segment.flightNumber),
  };
}

/**
 * Build the ReservationInput object for Email Service.
 *
 * @param {object} booking - The booking store.
 * @param {object} headPassengerManager.
 * @param {object} checkInPassengersManager.
 * @param {object} receipt - The receipt store.
 * @param {string} currencyCode - Currency code.
 * @param {string} languageCode - Language code
 * @param {string} recipientEmail - The email address of the recipient.
 */
function buildReservationInput(
  booking,
  headPassengerManager,
  checkInPassengersManager,
  receipt,
  currencyCode,
  languageCode,
  recipientEmail,
) {
  const bookingData = get(booking);
  const passengers = headPassengerManager.sortPassengers();
  const headPassenger = passengers[0];
  const emailTaskName = 'BoardingPass';
  const segments = booking.getDisplayedSegments(false);

  const retval = {
    bookingReference: bookingData.bookingReference,
    currencyCode: currencyCode,
    emailTaskName: emailTaskName,
    languageCode: languageCode.toUpperCase(),
    flights: segments.map((segment) => {
      return {
        airlineCode: segment.airlineCode,
        arrivalCity: segment.arrivalCode,
        arrivalDate: segment.arrivalDateTime,
        boardingTime: booking.getBoardingTime(
          headPassenger,
          segment.flightNumber,
        ),
        boardingZone: booking.getZoneNumber(
          headPassenger,
          segment.flightNumber,
        ),
        departureCity: segment.departureCode,
        departureDate: segment.departureDateTime,
        departureGate:
          booking.getDepartureGate(headPassenger, segment.flightNumber) ||
          'GATE',
        departureTerminal: booking.getDepartureTerminal(
          headPassenger,
          segment.flightNumber,
        ),
        flightNumber: stripLeadingZeroes(segment.flightNumber),
      };
    }),
    passengers: passengers.map((passenger) => {
      return {
        firstName: passenger.firstName,
        lastName: passenger.lastName,
        title: booking.getTitle(passenger),
        passengerID: passenger.passengerID,
        frequentFlyerShortText: booking.getFrequentFlyerShortText(passenger),
        frequentFlyerNumber: booking.getFrequentFlyerNumber(passenger),
        hasInfant: booking.passengerHasInfant(passenger) ? 'true' : null,
        flights: segments.map((segment) => {
          return {
            airlineCode: segment.airlineName,
            barcode: booking.getBarcode(passenger, segment.flightNumber),
            classOfService: booking.getBookingCode(
              passenger,
              segment.flightNumber,
            ),
            bookingClass: booking.getBookingCode(
              passenger,
              segment.flightNumber,
            ),
            issueAgency: bookingData.airlineCode,
            issueDate: dateToAirportFormat(new Date()),
            seatNumber: booking.getSeatNumber(passenger, segment.flightNumber),
            sequenceNumber: String(
              booking.getSequenceNumber(passenger, segment.flightNumber),
            ).padStart(3, '0'),
            staffCode: passenger.staffPriorityCode || null,
            ticketNumber: booking.getTicketNumber(passenger),
            bagTagNumbers: !headPassengerManager.passengerIsHead(passenger)
              ? null
              : get(receipt).map((receiptItem) => receiptItem.tagNumber),
            deck: booking.getDeck(passenger, segment.flightNumber),
          };
        }),
      };
    }),
    recipients: [recipientEmail].map((emailAddress) => {
      return { emailAddress };
    }),
  };
  
  return retval;
}

/**
 * Build the UpdateEmailInput object for Update Email Address.
 *
 * @param {string} bookingReference - The booking reference.
 * @param {string} emailAddress
 * @param {object} checkInPassengersManager - The singleton.
 */
function buildUpdateEmailInput(
  bookingReference,
  emailAddress,
  headPassengerManager,
) {
  const passengers = headPassengerManager.sortPassengers();
  const headPassenger = passengers[0];
  const emailData = {
    bookingReference: bookingReference,
    emailAddress: emailAddress,
    customer: {
      title: headPassenger.title,
      lastName: headPassenger.lastName,
      firstName: headPassenger.firstName,
      nameReferenceNumber: headPassenger.nameRefNumber,
    },
  };
  return emailData;
}

/**
 * Build the buildPriceUnpaidItemsInput object for Amadeus.
 *
 * @param {object} bookingDetails - The booking object.
 */
function buildPriceUnpaidItemsInput(bookingDetails, isBaggagePayment) {
  let passengers = [];

  if (isBaggagePayment) {
    // getting the bag group owner to find out the unpaid items
    var passenger = bookingDetails.passengers.filter(p => p.passengerID === bookingDetails.bagGroupOwnerPassengerID);
    
    var adultPassengers = bookingDetails.passengers.filter(p => p.passengerType !== 'INFANT');
    var firstAdultPassenger = null;
    if (adultPassengers && adultPassengers.length > 0) {
      firstAdultPassenger = adultPassengers[0];
    } else {
      firstAdultPassenger = get(headPassenger);
    }

    let pax = passenger && passenger.length > 0 ? passenger[0] : firstAdultPassenger;
    passengers.push(
      {
        surname: pax.lastName,
        customerPrimeId: pax.passengerID,
        productPrimeId: pax.passengerDID,
        flightNumber: bookingDetails.flightNumber,
        boardPoint: bookingDetails.originCode,
        airlineCode: bookingDetails.airlineCode,
        departureDate: bookingDetails.departureDate,
      }
    );
  } else {
    bookingDetails.passengers.forEach(p => {
      passengers.push(
        {
          surname: p.lastName,
          customerPrimeId: p.passengerID,
          productPrimeId: p.passengerDID,
          flightNumber: bookingDetails.flightNumber,
          boardPoint: bookingDetails.originCode,
          airlineCode: bookingDetails.airlineCode,
          departureDate: bookingDetails.departureDate,
        }
      );    
    })
  }

  const priceUnpaidData = {
    passengersList: passengers, 
    baseStation: baseStationCode,
    transactionId: get(transactionId)
  };



  return priceUnpaidData;
}

/**
 * Build the buildIssuePaymentDocumentInput object for Amadeus.
 *
 * @param {object} bookingDetails - The booking object.
 * @param {object} priceUnpaidItemsData - The booking object.
 */
function buildIssuePaymentDocumentInput(priceUnpaidItemsData) {
  let unpaidItems = priceUnpaidItemsData?.data?.priceUnpaidItems?.unpaidItems;
  
  let customers = [];
  let customerID;

  // storing distinct customers
  if (unpaidItems && unpaidItems.length > 0) {
    unpaidItems.forEach(ui => {
      if (customerID !== ui.passengerID) {
        customers.push(
          {
            customerPrimeId: ui.passengerID,
            surname: ui.surname,
            customerPricing: []
          }
        );
        customerID = ui.passengerID;
      }
    });

    unpaidItems.forEach(ui => {

      customers.forEach(c => {
        if (c.customerPrimeId === ui.passengerID) {
          c.customerPricing.push(
            {
              id: ui.pricingRecordId,
              serviceType: ui.serviceType,
              serviceValue: ui.serviceValue
            }
          )
        }
      })
    });
  }
  
  const issuePaymentDocumentInput = {
    customers: customers,   
    baseStation: baseStationCode,
    transactionId: get(transactionId)
  }

  return issuePaymentDocumentInput;
}

/**
 * Check Switchboard response status, error on an unsuccessful status.
 *
 * @throws {Error} - By default, throws an error if the response is not
 *                   successful. The Switchboard message field is included
 *                   in the thrown error.
 * @param {object} response - The response object.
 * @param {boolean} throwError - Whether or not to throw an error. Defaults to
 *                              true. If set to false, the function will
 *                              return a Boolean.
 * @returns {boolean} If the throwError param is false, return indicates
 *                   whether or not the reponse is successful.
 *
 */
export function raiseErrorOnBadResponse(response, throwError = true) {
  const operation = Object.keys(response.data)[0];
  const responseBody = response.data[operation];
  const success = RegExp(/success/i).test(responseBody.status);

  if (throwError && !success) {
    throw new Error(
      `Error in Switchboard ${operation} response. ` +
      `Message: ${responseBody.message}.`,
    );
  }

  return success;
}

export default new SwitchboardClient();
