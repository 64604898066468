<script>
  import { _ } from 'svelte-i18n';

  import { hotkey } from '../../../../js/actions';

  import Button from '../../Button.svelte';

  export let closeMenuHandler = () => {};
  export let hasAgreedToConditions = false;
</script>

<div class="mt-2">
  <h4 class="border-b border-current font-AltisMedium mb-2 pb-1 text-xl">
    {$_('seatMap.emergencyExitSeatDisclaimer.heading')}
  </h4>

  <div class="leading-snug">
    <p class="mb-2">{$_('seatMap.emergencyExitSeatDisclaimer.textLine1')}</p>

    <p class="font-AltisMedium mb-2">
      {$_('seatMap.emergencyExitSeatDisclaimer.textLine2')}
    </p>

    <p class="">{$_('seatMap.emergencyExitSeatDisclaimer.textLine3')}</p>

    <ul class="mb-2">
      <li>- {$_('seatMap.emergencyExitSeatDisclaimer.condition1')}</li>
      <li>- {$_('seatMap.emergencyExitSeatDisclaimer.condition2')}</li>
      <li>- {$_('seatMap.emergencyExitSeatDisclaimer.condition3')}</li>
      <li>- {$_('seatMap.emergencyExitSeatDisclaimer.condition4')}</li>
      <li>- {$_('seatMap.emergencyExitSeatDisclaimer.condition5')}</li>
      <li>- {$_('seatMap.emergencyExitSeatDisclaimer.condition6')}</li>
      <li>- {$_('seatMap.emergencyExitSeatDisclaimer.condition7')}</li>
      <li>- {$_('seatMap.emergencyExitSeatDisclaimer.condition8')}</li>
      <li>- {$_('seatMap.emergencyExitSeatDisclaimer.condition9')}</li>
    </ul>

    <p class="font-AltisMedium mb-2">
      {$_('seatMap.emergencyExitSeatDisclaimer.textLine4')}
    </p>
  </div>

  <div class="flex items-end">
    <Button
      class="px-20"
      size="small"
      on:click={() => (hasAgreedToConditions = true)}
    >
      {$_('app.accept')}
    </Button>

    <button
      class="mx-7 text-gold-100 underline"
      role="button"
      use:hotkey
      on:click={closeMenuHandler}
    >
      {$_('app.noThanks')}
    </button>
  </div>
</div>
