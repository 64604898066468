<script>
  import Cabin from './Cabin.svelte';

  export let cabinHeight = null;
  export let cabinWidth = null;
  export let isOpen = null;
  export let passenger = null;
  export let passengerSeats = null;
  export let previewSeat = null;
  export let seatMapManager = null;
  export let seatSpace = null;
  export let seatWidth = null;
  export let selectedSeat = null;
  export let spacerWidth = null;
</script>

<Cabin
  bind:cabinHeight
  bind:isOpen
  {cabinWidth}
  {passenger}
  {passengerSeats}
  {previewSeat}
  {seatMapManager}
  {seatSpace}
  {seatWidth}
  {selectedSeat}
  {spacerWidth}
  on:selectSeat
  on:unSelectSeat
  on:scrollToSeat
/>
