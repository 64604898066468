<script>
  import { _ } from 'svelte-i18n';
  import { createEventDispatcher } from 'svelte';
  import { rightToLeft } from '../../../js/stores';
  import Button from '../Button.svelte';
  import EmergencyExitSeatDisclaimer from './seats/EmergencyExitSeatDisclaimer.svelte';
  import SeatContentButtons from './SeatContentButtons.svelte';
  import Seat from './Seat.svelte';

  import x from '../../../svg/x.svg';

  const dispatch = createEventDispatcher();

  export let description = null;
  export let hasError = null;
  export let isOpen = null;
  export let passenger = null;
  export let passengerSeats = null;
  export let seat = null;

  let isSeatContentVariation = true;

  $: hasAgreedToConditions = false;

  /** Handle close of the tooltip. */
  function closeMenuHandler() {
    hasAgreedToConditions = false;
    hasError = false;
    isOpen = false;

    dispatch('unSelectSeat');
  }
</script>

<style>
  .seatContent {
    @apply absolute
      bottom-0
      left-0
      right-0;
  }
</style>

{#if isOpen}
  <div class="seatContent" dir={$rightToLeft ? 'rtl' : 'ltr'}>
    <div class="bg-white m-2 p-4 relative shadow-xl" style="border: 1px solid;">
      {#if hasError}
        <div class="mb-4">
          <p class="text-lg whitespace-pre-wrap">{$_('seatMap.error')}</p>
        </div>
        <div class="flex items-end">
          <Button class="px-8" size="small" on:click={closeMenuHandler}>
            {$_('app.ok')}
          </Button>
        </div>
      {:else}
        <div class="flex justify-between">
          {#if seat.isEmergencyExit() && !hasAgreedToConditions}
            <div class="w-full">
              <EmergencyExitSeatDisclaimer
                {closeMenuHandler}
                bind:hasAgreedToConditions
              />
            </div>
          {:else}
            <div style="margin-top: 35px;">
              <Seat {isSeatContentVariation} {passengerSeats} {seat} />

              <div class="text-lg" style="padding-top: 35px;">
                <p>{seat} ({$_('seatMap.selected')})</p>

                {#if description}
                  <p class="whitespace-no-wrap">
                    {$_(`seatMap.${description}`)}
                  </p>
                {/if}

                {#if seat.isAisleSeatLocation(seat)}
                  <p class="whitespace-no-wrap">
                    {$_('seatMap.aisleSeat')}
                  </p>
                {/if}

                {#if seat.isWindowSeatWithoutWindow()}
                  <p class="whitespace-no-wrap">
                    {$_('seatMap.windowSeatWithoutWindow')}
                  </p>
                {/if}

                {#if seat.isCenterSeat()}
                  <p class="whitespace-no-wrap">
                    {$_('seatMap.centerSeatNotWindowNotAisle')}
                  </p>
                {/if}

                {#if seat.isFrontOfCabin()}
                  <p class="whitespace-no-wrap">
                    {$_('seatMap.frontOfCabinClassCompartment')}
                  </p>
                {/if}

                {#if seat.isLeftSideOfAircraft()}
                  <p class="whitespace-no-wrap">
                    {$_('seatMap.leftSideOfAircraft')}
                  </p>
                {/if}

                {#if seat.isSeatWithoutAMovieView()}
                  <p class="whitespace-no-wrap">
                    {$_('seatMap.seatWithoutaMovie')}
                  </p>
                {/if}
              
                {#if seat.isPreferentialSeat()}
                  <p class="whitespace-no-wrap">
                    {$_('seatMap.preferentialSeat')}
                  </p>
                {/if}

                {#if seat.isRightSideOfAircraft()}
                  <p class="whitespace-no-wrap">
                    {$_('seatMap.rightSideOfAircraft')}
                  </p>
                {/if}

                {#if seat.isWindowAndAisleTogether()}
                  <p class="whitespace-no-wrap">
                    {$_('seatMap.windowAndAisleTogether')}
                  </p>
                {/if}

                {#if seat.isWindow()}
                  <p class="whitespace-no-wrap">
                    {$_('seatMap.windowSeat')}
                  </p>
                {/if}
                
                <p class="font-AltisMedium">
                  {#if seat.isPaid()}
                    {$_('seatMap.nonRefundable')}
                  {:else}
                    <span class="invisible">.</span>
                  {/if}
                </p>
              </div>
            </div>

            <div class="flex items-end">
              <SeatContentButtons
                {closeMenuHandler}
                {seat}
                {passenger}
                on:openPaymentModal
                on:openPaymentWarningModal
                on:updateSelectedSeat
              />
            </div>
          {/if}
        </div>

        <div class="absolute p-4 top-0 {$rightToLeft ? 'left-0' : 'right-0'}">
          <button on:click={closeMenuHandler} class="px-2 py-1">
            <div class="w-7 text-charcoal-100">
              {@html x}
            </div>
          </button>
        </div>
      {/if}
    </div>
  </div>
{/if}
