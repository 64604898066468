<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { onMount } from 'svelte';
  import { push } from 'svelte-spa-router';

  import { ApplicationStep, appReport, FailedReason } from '../../js/appReport';  
  import {
    booking,
    checkInPassengersManager,
    infants,    
    setErrorModal,
    wizardPosition,
    originalSeatOfPassengers
  } from '../../js/stores';  
  import { ErrorModals } from '../../js/const/errorModals';
  import { WizardPosition } from '../../js/const';
  import logger from '../../js/logger';
  import switchboardClient from '../../js/services/switchboard';
  import flightdeck from '../../js/services/flightdeck'; 
  import Footer from '../components/Footer/index.svelte';
  import Header from '../components/Header/index.svelte';
  import Loading from '../components/Loading.svelte';

  wizardPosition.set(WizardPosition.PASSENGERS);

  onMount(() => {
    appReport.updateStepStart(ApplicationStep.PASSENGER_ACCEPTANCE);

    const passengerList = getPassengerList();

    switchboardClient.initiateCustomerAcceptance(passengerList).then(
      (response) => {
        logger.info(
          'Initiate Customer Acceptance response received: ',
          response,
        );

        let errorMessages =
          response?.data?.initiateCustomerAcceptance?.errorMessages;

        if (errorMessages && errorMessages?.length > 0) {
          logger.error('Errors received from Initiate customer response...');
          errorMessages.forEach((error) => {
            logger.error(error);
          });

          setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);      
          
          /** Special case of checked in but APP error EYSS-3382 */ 
          if(response?.data?.initiateCustomerAcceptance?.isCustomerAccepted && response?.data?.initiateCustomerAcceptance?.isAPPErrorSeen)
          {
            flightdeck.customerAcceptanceFailedWithReason("Passenger accepted but APP Authorization Status Not Clear. Transaction ended.");        
          } else if (response?.data?.initiateCustomerAcceptance?.isAPPErrorSeen) {
            flightdeck.customerAcceptanceFailedWithReason("APP Authorization Status Not Clear. Transaction ended");        
          } else {
            flightdeck.customerAcceptanceFailed();
          }
          return;          
        }
        // update the passengers details in the booking with sequence numbers received....
        let passengers =
          response?.data?.initiateCustomerAcceptance?.passengerList;
        if (passengers && passengers.length > 0) {
          passengers.forEach((passengerInResponse) => {
            get(booking).passengers.forEach((passengerInBooking) => {
              // update the sequenceNumber field in passenger object
              if (
                passengerInBooking.passengerID ===
                passengerInResponse.customerPrimeId
              ) {
                passengerInBooking.sequenceNumber = passengerInResponse.securityNumber;
                passengerInBooking.seatNumber = passengerInResponse.seatNumber;
              }

              // update the sequenceNumber field in passenger.segment.sequenceNumber
              passengerInBooking.segments.forEach((segment) => {
                if (
                  segment.passengerDID === passengerInResponse.productPrimeId
                ) {
                  segment.sequenceNumber =
                    passengerInResponse.securityNumber;
                    segment.seatNumber = passengerInResponse.seatNumber
                }
              });
            });
          });
        }

        // storing original seats of the passengers
        let seatNumberOfPassengers = get(originalSeatOfPassengers);
        get(booking).passengers.forEach(p => {
          p.segments.forEach(s => {
            // if seatNumberOfPassengers already has values
            // we can override all passengers and segments 
            // since these seat numbers are assigned directly after checkin
            seatNumberOfPassengers.push
            ({
              passengerID: p.passengerID,
              passengerDID: s.passengerDID,
              flightNumber: s.flightNumber,
              seatNumber: s.seatNumber,
              isSeatPurchased: s.isSeatPurchased
            });
          });
        });
        originalSeatOfPassengers.set(seatNumberOfPassengers);

        logger.info(`The original seat numbers of the passengers are: ${JSON.stringify(get(originalSeatOfPassengers))}`);

        /**
         * If we get to here, it means no errors were found on pax acceptance.
         * And in further steps (eg place bag) if errors are found we still want to keep track of this
         * so we can send them to the BD completed screen.
         */
        checkInPassengersManager.markPassengersCheckInSuccessfully()

        appReport.updateStepSuccess(ApplicationStep.PASSENGER_ACCEPTANCE);
        
        if (!booking.isAnyPassengerStaffandStandBy()) {
          push('/seat-map');
        } else {
          push('/number-of-bags');
        }
      },
      (error) => {
        logger.warn('Initiate Customer Acceptance error received: ', error);
        appReport.updateStepFailed(
          ApplicationStep.PASSENGER_ACCEPTANCE,
          FailedReason.SWITCHBOARD_CALL_FAILED
        );
        flightdeck.customerAcceptanceFailed();
      },
    );    
  });

  function getPassengerList() {
    let passengersList = [];
    const bookingDetails = get(booking);

    // checking if we have any infants
    const infantPassengers = get(infants);
    if (infantPassengers && infantPassengers.length > 0) {
      infantPassengers.forEach((infant) => {
        infant.segments.forEach((segment) => {
          let passengerDetails = {
            surname: infant.lastName,
            type: infant.amadeusPassengerType,
            customerPrimeId: infant.passengerID,
            marketingCarrier: segment.marketingAirlineCode,
            flightNumber: segment.flightNumber,
            departureDate: segment.departureDateTime,
            boardPoint: segment.departureCode,
            productPrimeId: segment.passengerDID,
          };
          passengersList.push(passengerDetails);
        });
      });
    }

    bookingDetails.passengers.forEach((passenger) => {
      passenger.segments.forEach((segment) => {
        let passengerDetails = {
          surname: passenger.lastName,
          type: passenger.amadeusPassengerType,
          customerPrimeId: passenger.passengerID,
          marketingCarrier: segment.marketingAirlineCode,
          flightNumber: segment.flightNumber,
          departureDate: segment.departureDateTime,
          boardPoint: segment.departureCode,
          productPrimeId: segment.passengerDID,
        };
        passengersList.push(passengerDetails);
      });
    });

    return passengersList;
  }
</script>

<Header />

<Loading heading={$_('app.pleaseWait')} />

<Footer />
