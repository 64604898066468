<script>
  import { _ } from 'svelte-i18n';
  import { onMount } from 'svelte';
  import { push } from 'svelte-spa-router';
  import { get } from 'svelte/store';
  import { ApplicationStep, appReport } from '../../js/appReport';
  import {
    booking,
    checkInPassengersManager,
    headPassenger,
    wizardPosition,
    selectedYesToSeatMapSelection
  } from '../../js/stores';
  import { WizardPosition } from '../../js/const';
  import { VoiceIntent } from '../../js/services/voicerec/voicerec';

  import BoardingPass from '../components/modal/BoardingPass.svelte';
  import BookingSummaryWidget from '../components/modal/BookingSummaryWidget.svelte';
  import ChevronRight from '../components/Icons/ChevronRight.svelte';
  import Content from '../components/Content.svelte';
  import Footer from '../components/Footer/index.svelte';
  import FooterSecondary from '../components/FooterSecondary.svelte';
  import Header from '../components/Header/index.svelte';
  import OnwardFlightsWidget from '../components/modal/OnwardFlightsWidget.svelte';
  import PassengerSummaryPaginated from '../components/PassengerSummaryPaginated.svelte';
  import ToggleButton from '../components/ToggleButton.svelte';
  import RadioButton from '../components/RadioButton.svelte';
  import RadioButtonGroup from '../components/RadioButtonGroup.svelte';

  wizardPosition.set(WizardPosition.PASSENGERS);

  let showCheckedIn = false;
  let showPrintBoardingPassModal = null;
  let showNonCheckedIn = false;
  
  const options = [{text: $_('app.yes'), value: true}, {text: $_('app.no'), value: false}];

  /** Advance to the next screen as per the applicationFlow. */
  function advanceToNextScreen() {
    appReport.updateStepSuccess(ApplicationStep.FLIGHT_SUMMARY);
    push('/passport-verification/');
  }

  /** The handler for the Boarding Pass button. */
  function boardingPassHandler() {
    if (booking.hasCheckedInPassengers()) {
      showPrintBoardingPassModal = true;
    } else {
      advanceToNextScreen();
    }
  }

  /** Handle request to display checked in passengers. */
  function showCheckedInPassengers() {
    showCheckedIn = true;
    showNonCheckedIn = false;
  }

  /** Handle request to display non-checked in passengers. */
  function showNonCheckedInPassengers() {
    showCheckedIn = false;
    showNonCheckedIn = true;
  }

  let checkedInPassengers, hasPassengersToCheckIn, nonCheckedInPassengers;

  onMount(() => {
    appReport.updateStepStart(ApplicationStep.FLIGHT_SUMMARY);
    checkedInPassengers = booking.checkedInPassengers();
    nonCheckedInPassengers = booking.uncheckedInPassengers();
    hasPassengersToCheckIn = checkInPassengersManager.hasCheckInPassengers();
    
    showCheckedIn = checkedInPassengers.length > 0;
    if (!showCheckedIn) {
      showNonCheckedIn = hasPassengersToCheckIn;
    }

    const headPax = get(headPassenger);
    if (headPax) {
      booking.setPNRInBooking(headPax.bookingReference);
      booking.setPNR(headPax.bookingReference);
    }
  });

  function changeSeatHandler() {
    selectedYesToSeatMapSelection.set(true);

    boardingPassHandler();
  }
</script>

<BoardingPass
  {advanceToNextScreen}
  bind:showModal={showPrintBoardingPassModal}
/>

<Header />

<Content>
  <div slot="main" class="relative">
    <BookingSummaryWidget />
    <OnwardFlightsWidget />

    {#if booking.hasCheckedAndNonCheckedPassengers()}
      <div class="flex justify-end mt-5 mb-2">
        <div>
          <ToggleButton
            active={showCheckedIn}
            handler={() => showCheckedInPassengers()}
          >
            {$_('passengerSummaryHeadings.checkedIn')}
          </ToggleButton>
        </div>
        <div>
          <ToggleButton
            active={showNonCheckedIn}
            handler={() => showNonCheckedInPassengers()}
          >
            {$_('passengerSummaryHeadings.notCheckedIn')}
          </ToggleButton>
        </div>
      </div>
    {/if}

    <div class="mt-2 h-90 relative">
      {#if showCheckedIn}
        <PassengerSummaryPaginated 
          passengers={checkedInPassengers || []}
          disablePaginationForPassportScan={false} 
          isGuidedFlow={checkedInPassengers?.length === 0 && nonCheckedInPassengers?.length > 0}
        />
      {/if}
      {#if showNonCheckedIn}
        <PassengerSummaryPaginated
          passengers={checkInPassengersManager.getAdults() || []}
          disablePaginationForPassportScan={false}
          isGuidedFlow={checkedInPassengers?.length === 0 && nonCheckedInPassengers?.length > 0}
        />
      {/if}
    </div>
    
    <FooterSecondary
      buttonRightHandler={boardingPassHandler}
      buttonRightText={'All Passports Scanned'}
      buttonLeftVisible={!booking.isAnyPassengerStaffandStandBy() && (!booking.hasUncheckedInPassengers() && !booking.hasPassengersRequiringADCCheck())}
      buttonLeftHandler={changeSeatHandler}
      buttonLeftText={(!booking.isAnyPassengerStaffandStandBy() && (!booking.hasUncheckedInPassengers() && !booking.hasPassengersRequiringADCCheck())) ? $_('seatMap.changeSeat') : null}
      hasTripExtrasBanner
    />
  </div>
</Content>

<Footer />
