<script>
  import Bassinet from './seats/Bassinet.svelte';

  export let seat = null;
  export let seatType = null;
  export let isSeatContentVariation = false;
  export let text = null;
</script>

<style>
  .seat {
    @apply relative
      h-12
      w-12;
  }

  .bassinet {
    @apply absolute
      left-0
      right-0
      mx-auto
      w-7;
  }

  .bulkhead {
    @apply absolute
      border-t-2
      border-gold-100
      left-0
      -mt-10
      right-0
      top-0;
    margin-left: -6px;
    margin-right: -6px;
  }

  .lavatory {
    @apply p-1;
  }

  .text {
    @apply absolute
      flex
      font-AltisMedium
      inset-0
      items-center
      justify-center
      px-3;
  }
</style>

<div class="seat" data-seatNumber={seat}>
  {#if text}
    <div class="text">
      <p
        class="{seat.isRearFacing()
          ? 'mt-2'
          : 'mb-2'} bg-white leading-none
        text-0.625 uppercase"
      >
        {text}
      </p>
    </div>
  {/if}

  {#if seat.isBulkhead()}
    <div class="bulkhead" />
  {/if}
  {#if seat.isBassinet()}
    <div
      class="bassinet {seat.isRearFacing()
        ? 'bottom-0 -mb-8'
        : 'top-0 -mt-8'}"
    >
      <Bassinet />
    </div>
  {/if}

  <div class:lavatory={seat.isLavatory()}>
    <svelte:component this={seatType} />
  </div>
</div>
