<script>
  import Lavatory from '../../../svg/lavatory.svg';
  import Galley from '../../../svg/galley.svg';
  import Bar from '../../../svg/bar.svg';
  import Stairs from '../../../svg/stairs.svg';

  export let row = null;
  export let seatMapManager = null;
</script>

<style>
  .rowFacilities {
    @apply flex justify-around;
  }
</style>

<div class="rowFacilities" style="margin-bottom: 36px;">
  {#each seatMapManager.getRowFacilities(row) as facility}
    {#if facility.facility[0].characteristics === 'Lavatory'}
      <div class="pb-5">
        <div class="bg-charcoal-10 flex justify-center my-4 w-27">
          <div class="w-10 py-3">
            {@html Lavatory}
          </div>
        </div>
      </div>
    {:else if facility.facility[0].characteristics === 'Galley'}
      <div class="pb-5">
        <div class="bg-charcoal-10 flex justify-center my-4 w-27">
          <div class="w-10 py-3">
            {@html Galley}
          </div>
        </div>
      </div>
    {:else if facility.facility[0].characteristics === 'Bar'}
      <div class="pb-5">
        <div class="bg-charcoal-10 flex justify-center my-4 w-27">
          <div class="w-10 py-3">
            {@html Bar}
          </div>
        </div>
      </div>
    {:else if facility.facility[0].characteristics === 'StairstoUpperdeck'}
      <div class="pb-5">
        <div class="bg-charcoal-10 flex justify-center my-4 w-27">
          <div class="w-10 py-3">
            {@html Stairs}
          </div>
        </div>
      </div>
    {:else}
      <div class="w-27" />
    {/if}
  {/each}
</div>
