<script>
  import { _ } from 'svelte-i18n';
  import logger from '../../../js/logger';
  import Button from '../Button.svelte';
  import ModalBase from '../ModalBase.svelte';

  export let showModal = false;
  export let passengerAcceptHandler = () => {};
  export let passengerDeclineHandler = () => {};
 
</script>

<ModalBase
  heading={$_('seatChangePaymentRequired.heading')}
  textLine1={$_('seatChangePaymentRequired.textLine1')}
  bind:showModal
>
  <div class="flex justify-between mt-auto">
    <Button on:click={passengerDeclineHandler}>
      {$_('app.decline')}
    </Button>

    <Button on:click={passengerAcceptHandler}>
      {$_('app.accept')}
    </Button>
  </div>
</ModalBase>