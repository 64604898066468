<script>
  import { _ } from 'svelte-i18n';
  import { get } from 'svelte/store';
  import { onMount } from 'svelte';
  import { push } from 'svelte-spa-router';
  import mrzParse from 'mrz';
  import events from '../../js/events';

  import { ApplicationStep, appReport } from '../../js/appReport';
  import { FeatureFlags, TransactionInitiator } from '../../js/const';
  import { ErrorModals } from '../../js/const/errorModals';
  import {
    appTransferDataContainingPassportMRZ,
    bioMrz,
    boardingPass,
    booking,
    bookingRetrieved,
    checkInPassengersManager,
    enableEligibilityCheck,
    featureFlags,    
    headPassengerManager,
    mrz,
    passportNumbers,
    passengerMrzManager,    
    pnr,    
    setErrorModal,    
    userInitiationMethod,
    originalSeatOfPassengers
  } from '../../js/stores';

  import switchboardClient from '../../js/services/switchboard';
  import flightdeck from '../../js/services/flightdeck';
  import logger from '../../js/logger';

  import Header from '../components/Header/index.svelte';
  import Loading from '../components/Loading.svelte';

  import checkCircle from '../../svg/checkCircle.svg';

  export let params = {};

  const ManualPNREntryCriteria = [
    'GET_TICKET_DOC_FAILED',
    'SEGMENT_NOT_FOUND',
    'TRIP_NOT_FOUND',
    'TRIP_SEARCH_FAILED',
  ];

  /**
   * Search Booking Validation Check
   * @param {object} response - A response object from switchboard.
   */
  function validateResponse(response) {
    const { data } = response;
    const { searchBooking } = data;

    if (!data) {
      throw new Error('Bad response.');
    } else if (!searchBooking) {
      const { message } = data;
      throw new Error(`Bad response. Message: ${message}.`);
    } else if (!'eligibilityCheck' in searchBooking) {
      throw new Error('Bad response. Missing eligibilityCheck in response.');
    } else if (
      get(userInitiationMethod) === TransactionInitiator.PASSPORT &&
      ManualPNREntryCriteria.includes(searchBooking.eligibilityCheck)
    ) {
    
      logger.info(
        `Moving to manual PNR entry. ` +
          `Passport booking retrieval had result: ` +
          `${searchBooking && searchBooking.eligibilityCheck}.`,
      );

      push('/pnr-searching'); // if the passport scan failed to retrieve booking, offer pnr entry screen to passenger
      return false;
    
    } else if (
      searchBooking.isPCRTestNotRecorded &&
      featureFlags.isEnabled(FeatureFlags.PCR_TEST)
    ) {
      logger.info('Failed PCR Test.');
      flightdeck.PCRTestFailed();
      setErrorModal(ErrorModals.PCR_TEST_NOT_RECORDED);
      return false;
    } else if (searchBooking.eligibilityCheck == 'CCNV_SSR_FOUND') {
      logger.info('Found CCNV SSR.');
      flightdeck.ccnvSSRFound();
      setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
      return false;
    } else if (
      featureFlags.isDisabled(FeatureFlags.MULTI_SECTOR) &&
      booking.getDisplayedSegments(false, searchBooking.segments).length > 1
    ) {
      flightdeck.multiSectorBooking();
      setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
      return false;
    } else if (searchBooking.eligibilityCheck && get(enableEligibilityCheck)) {
      logger.info(
        `Failed eligibilityCheck. ${
          searchBooking && searchBooking.eligibilityCheck
        }`,
      );
      flightdeck.eligibilityCheckFailure(searchBooking.eligibilityCheck);
      throw new Error(searchBooking.eligibilityCheck);
    } else if (
      !searchBooking.passengers ||
      searchBooking.passengers.length === 0
    ) {
      throw new Error('No passengers.');
    }

    logger.info('Booking response validated.');
    return true;
  }

  /**
   * Switchboard Search Booking based on PNR - BOARDING PASS - PASSPORT - BIOMETRIC
   */
  function updateBooking() {
    const bookingSearchQuery = {};
    const initiationMethod = get(userInitiationMethod);
    logger.info(`initiationMethod: ${initiationMethod}`);

    switch (initiationMethod) {
      case TransactionInitiator.PNR:
        bookingSearchQuery.pnr = $pnr;
        break;
      case TransactionInitiator.BOARDING_PASS:
        bookingSearchQuery.barcode = $boardingPass;
        break;
      case TransactionInitiator.PASSPORT:
        bookingSearchQuery.mrz = $mrz && Array.isArray($mrz) && $mrz.join('|');
        break;
      case TransactionInitiator.BIOMETRIC:
        bookingSearchQuery.mrz = $bioMrz;
        break;
      default:
        logger.warn('No user input, so booking retrieval aborted.');
        return;
    }

    switchboardClient
      .bookingSearch(bookingSearchQuery)
      .then((response) => {        
        // booking is a store var which was introduced in SABRE
        // in order to reuse the current functionality, we assign
        // the response to it like before
        booking.set(response.data.searchBooking);        
        booking.setPNR();


        if (validateResponse(response)) {
          booking.compensateDateTimes();
          booking.setPassengers(get(booking).passengers);
          headPassengerManager.setLocale();
          booking.repairForInitialPassportScan();
          bookingRetrieved.set(true);
          logger.info('Booking data ready.');
          appReport.updateStepSuccess(ApplicationStep.BOOKING_RETRIEVAL);
          
          // storing original seats of the passengers
          // This screen is the first step. hence, we do not need to check
          // whats inside originalSeatOfPassengers
          let seatNumberOfPassengers = [];
          get(booking).passengers.forEach(p => {
            p.segments.forEach(s => {
              if (s.seatNumber) {
                seatNumberOfPassengers.push
                ({
                  passengerID: p.passengerID,
                  passengerDID: s.passengerDID,
                  flightNumber: s.flightNumber,
                  seatNumber: s.seatNumber,
                  isSeatPurchased: s.isSeatPurchased
                });
              }
            });
          });
          originalSeatOfPassengers.set(seatNumberOfPassengers);

          logger.info(`The original seat numbers of the passengers are: ${JSON.stringify(get(originalSeatOfPassengers))}`);

          // // if this booking is a single passenger transaction
          // // we need to see if the user started off with scanning passport
          if (
            booking.isSinglePassengerTransaction() &&
            $mrz &&
            $mrz.length > 0
          ) {
            addSinglePassengerPassport();
            return;
          }

          if (
            initiationMethod !== TransactionInitiator.PNR &&
            booking.isSinglePassengerTransaction() &&
            !booking.hasUncheckedInPassengers()
          ) {
            booking.setPNR(get(booking).passengers[0].bookingReference);
            push('/welcome-single');            
          } else {
            // if this is a return to bag drop case, 
            // we do not want to show passport scan screen
            // we will go directly to Number of bags
            if (get(booking).isBaggageInfoForScannedPassenger) {
              push('/passengers-in-baggage-pool');
            } else {
              push('/passport-to-camera');
            }
          }
        }
      })
      .catch((error) => {
        logger.warn(`Booking not found. Error: ${error.message || error}.`);
        setErrorModal(ErrorModals.ASSISTANCE_REQUIRED);
      });
  }

  /** This method is called to address a scenario where
      User starts of by scanning his passport (MRZ), we
      should then remember their passport information
      even after they are asked to enter their PNR
    */
  function addSinglePassengerPassport() {
    try {
      let mrzParseResults = mrzParse.parse($mrz);
      const { documentNumber } = mrzParseResults.fields;
      passportNumbers.insert(documentNumber);
      const isBookingRetrieved = get(bookingRetrieved);

      // see if we can get the booking of this passenger
      // if yes then emit the MRZ scan event to add the
      // passport related information
      if (isBookingRetrieved) {
        events.emitMRZScan(
          booking.isMatchToPassengerDocS(documentNumber),
          $mrz,
          documentNumber,
        );

        checkInPassenger(documentNumber);
        
        booking.setPNR(get(booking).passengers[0].bookingReference);
        push('/welcome-single');
      }
    } catch (error) {
      logger.info(
        'Failed trying to add a single passenger passport information.',
      );
      throw new Error('Failed trying to add a single passenger passport information.')
    }
  }

  // CHECKING IN THE PASSENGER IF NOT ALREADY
  function checkInPassenger(documentNumber) {
    try {
      const passenger = booking.getSinglePassenger();
      if (passenger && $mrz && $mrz.length > 0) {
        // adding the passenger MRZ
        const addedPassengerMrz = passengerMrzManager.addPassengerMrz(
          passenger,
          $mrz,
        );

        // checking in the passenger, if not already
        if (!checkInPassengersManager.isCheckInPassenger(passenger)) {
          logger.info(
            `Addding passenger who initiated the transaction to the list ` +
              `of passengers who will be checked in, ` +
              `passenger ID: ${passenger && passenger.passengerID}.`,
          );
          checkInPassengersManager.addCheckInPassenger(
            passenger,
            documentNumber,
          );
        }
      }
    } catch (error) {
      logger.info('Failed trying to add a passenger to check-in.');
      throw new Error('Failed trying to add a passenger to check-in.')
    }
  }

  onMount(() => {
    logger.info('Entered SearchingForYourBooking.');
    appReport.updateStepStart(ApplicationStep.BOOKING_RETRIEVAL);
    updateBooking();
  });
</script>

<Header hasHomeButton={false} />

{#if params.hasScannedPassport}
  <div class="mt-28 text-center">
    <h1 class="mb-16">{$_('searching.scanComplete')}</h1>

    <div class="mb-14 mx-auto text-palmGreen w-34">
      {@html checkCircle}
    </div>
    {#if !$appTransferDataContainingPassportMRZ}
      <h2>{$_('searching.removePassport')}</h2>
    {/if}
  </div>
{/if}

<Loading heading={$_('searching.searchingForBooking')} />
