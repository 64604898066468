import { get } from 'svelte/store';

import logger from '../logger';
import { resettable } from './extensions/resettable';
import { safeObjectAttribute } from '../utils';
import switchboardClient, {
  raiseErrorOnBadResponse,
} from '../services/switchboard';
import { ErrorModals } from '../const/errorModals';
import { setErrorModal } from '../../js/stores';
import { booking } from "./booking"

const seatChanges = resettable({});

export const currentPassengerForSeatMap = resettable({});
export const seatSelectionPaymentAccepted = resettable([]);
export const paymentWarningModalShown = resettable(false); // once true, we do not want to show the modal again
export const seatPaymentAmountPerPassenger =  resettable([]);
export const originalSeatOfPassengers = resettable([]);
export const didAnyPassengerSeatChange = resettable(false);
export const seatPaymentProcessInProgress = resettable(false);

const offloadOccurred = resettable(false);
/**
 * Used for accessing seat changes.
 */
class SeatChangesManager {
  /**
   * Set a seat change and stores it.
   *
   * @param {object} passenger - Switchboard passenger object.
   * @param {string} flightNumber - The flight number.
   * @param {string} newSeat - The new seat number.
   */
  setSeatChange(passenger, flightNumber, newSeat) {
    // DC TODO: 
    // No idea what this seat change store is used for... maybe if a payment needs to be taken later??
    const oldSeatChanges = get(seatChanges);
    seatChanges.set({
      ...oldSeatChanges,
      [passenger.passengerID]: {
        ...oldSeatChanges[passenger.passengerID],
        [flightNumber]: newSeat,
      },
    });

    // Set the new seat on the booking
    booking.setSeatNumber(passenger, flightNumber, newSeat)
  }
  /**
   * In case of passenger gets offloaded by seat Select Change.
   */
  offloadOccurred() {
    return get(offloadOccurred);
  }
  /**
   * Get the seat number.
   *
   * @param {object} passenger - The Switchboard passenger object.
   * @param {string} flightNumber - The flight number.
   * @returns {string} The seat number.
   */
  getSeatNumber(passenger, flightNumber) {
    return safeObjectAttribute(
      get(seatChanges),
      `${passenger.passengerID}.${flightNumber}`,
    );
  }

  /**
   * Perform a passenger seat change.
   *
   * @param {object} booking
   * @param {object} passenger
   * @param {string} flightNumber
   * @param {string} seat
   * @param {string} flightdeck
   */
  performChangeSeat(booking, passenger, flightNumber, seat, flightdeck, amount) {
    logger.info(`Request allocateSeat.`);

    return switchboardClient
      .allocateSeat(booking, passenger, flightNumber, seat, amount)
      .then((response) => {
        if (response) {
          logger.info(`AllocateSeat response is : ${JSON.stringify(response)}).`);
        
          let errorMessages = response?.data?.allocateSeat?.errorMessages;
          if (errorMessages && errorMessages?.length > 0) {
            errorMessages.forEach((error) => {
              logger.info(error);
            });
            logger.info('Returning false from Allocate seat...');
            return false;
          }
        
          this.setSeatChange(passenger, flightNumber, String(seat));
          didAnyPassengerSeatChange.set(true);
          return true;
        } else {
          logger.info('No response received from seat allocate...');
          // TODO - show a information message that passenger seat is reverted to the previous one
          // flightdeck.addBaggageInformationFailed();
          setErrorModal(ErrorModals.SEAT_CHANGE_FAILED);
          return false;
        }
      })
      .catch((error) => {
        logger.warn(`Failed allocateSeat: ${error.message}`);
        throw error;
      });
  }

  /** Resets the svelte store. */
  reset() {
    seatChanges.reset();
  }
}

export const seatChangesManager = new SeatChangesManager();
